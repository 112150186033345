import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { api, getCookie, submitForm, pesan, tanggalIndo, numberFormat, saiki, router } from '../modul';
import Footer from '../component/Footer';
import img_upload from '../bg_uppload-image.png';

let dom = ReactDOM.createRoot(document.getElementById('root'));
function App(Tab = "Tunai") {
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                DocNumber: ""
            }
        }

        async componentDidMount() {
            let sql = await api("list_tagihan", { SalesID: getCookie("SalesID"), PaymentType: "", Token: getCookie("TokenSales") });
            if (sql.status == "true") this.setState({ data: sql.data, DocNumber: sql.DocNumber });
        }

        async handleProses() {
            let sql = await api("approve_sales_doc", { DocNumber: this.state.DocNumber, SalesID: getCookie("SalesID"), Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                pesan("Proses Success", sql.message, "primary");
                router();
            } else {
                pesan("Proses Error", sql.message, "danger");
            }
        }

        render() {
            let TabTunai = Tab == "Tunai" ? "nav-link active" : "nav-link",
                TabTransfer = Tab == "Transfer" ? "nav-link active" : "nav-link",
                TabGiro = Tab == "Giro" ? "nav-link active" : "nav-link",
                TabSetoran = Tab == "Setoran" ? "nav-link active" : "nav-link",
                PanTunai = Tab == "Tunai" ? "tab-pane fade show active" : "tab-pane fade",
                PanTransfer = Tab == "Transfer" ? "tab-pane fade show active" : "tab-pane fade",
                PanGiro = Tab == "Giro" ? "tab-pane fade show active" : "tab-pane fade",
                PanSetoran = Tab == "Setoran" ? "tab-pane fade show active" : "tab-pane fade";

            return (
                <Fragment>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <h5 style={{ textAlign: "center", fontSize: "14px" }}>Penagihan Setoran</h5>
                    </div>
                    <div className='container main' id='mainContent'>
                        <ul className="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className={TabTunai} id="pills-tunai-tab" data-bs-toggle="pill" data-bs-target="#pills-tunai" type="button" role="tab" aria-controls="pills-tunai" aria-selected="true">Tunai</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={TabTransfer} id="pills-transfer-tab" data-bs-toggle="pill" data-bs-target="#pills-transfer" type="button" role="tab" aria-controls="pills-transfer" aria-selected="false">Transfer</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={TabGiro} id="pills-giro-tab" data-bs-toggle="pill" data-bs-target="#pills-giro" type="button" role="tab" aria-controls="pills-giro" aria-selected="false">Giro</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={TabSetoran} id="pills-setoran-tab" data-bs-toggle="pill" data-bs-target="#pills-setoran" type="button" role="tab" aria-controls="pills-setoran" aria-selected="false">Riwayat</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className={PanTunai} id="pills-tunai" role="tabpanel" aria-labelledby="pills-tunai-tab" tabIndex="0">
                                <Tunai />
                            </div>
                            <div className={PanTransfer} id="pills-transfer" role="tabpanel" aria-labelledby="pills-transfer-tab" tabIndex="0">
                                <Transfer />
                            </div>
                            <div className={PanGiro} id="pills-giro" role="tabpanel" aria-labelledby="pills-giro-tab" tabIndex="0">
                                <Giro />
                            </div>
                            <div className={PanSetoran} id="pills-setoran" role="tabpanel" aria-labelledby="pills-giro-tab" tabIndex="0">
                                <Riwayat />
                            </div>
                        </div>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <Footer active="Penagihan" />
                    </div>
                </Fragment>
            )
        }
    }

    dom.render(<Main />);
}

// RIWAYAT
class Riwayat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            d1: saiki("01"),
            d2: saiki()
        }
    }

    async componentDidMount() {
        let sql = await api("list_tagihan_sales", { SalesID: getCookie("SalesID"), d1: this.state.d1, d2: this.state.d2, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ data: sql.data });
        }
    }

    async handleCari() {
        let sql = await api("list_tagihan_sales", { SalesID: getCookie("SalesID"), d1: this.state.d1, d2: this.state.d2, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ data: sql.data });
        }
    }

    render() {
        return (
            <Fragment>
                <div className='input-group gap-2'>
                    <input type="date" className="form-control" value={this.state.d1} onChange={(e) => this.setState({ d1: e.target.value })} />
                    <input type="date" className="form-control" value={this.state.d2} onChange={(e) => this.setState({ d2: e.target.value })} />
                    <button className='btn btn-primary' onClick={() => this.handleCari()}><span className='material-icons'>search</span></button>
                </div>
                <p></p>
                <div className='table-responsive'>
                    <table className='table table-bordered' style={{ minWidth: "800px" }}>
                        <thead className='bg-dark text-light'>
                            <tr>
                                <th>No</th>
                                <th>Tanggal</th>
                                <th>Pelanggan</th>
                                <th>Total</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{item.NoInvoice}</td>
                                            <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                            <td>{item.CustomerName}</td>
                                            <td>{numberFormat(item.Amount)}</td>
                                            <td>{item.State}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Fragment>
        )
    }
}

// SETORAN
class Setoran extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    async componentDidMount() {
        let sql = await api("list_setoran_sales", { SalesID: getCookie("SalesID"), Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ data: sql.data });
        }
    }

    handleAdd(ID = "") {
        dom.render(<FormSetoran ID={ID} />);
    }

    handleEdit(ID) {
        dom.render(<FormSetoran ID={ID} />)
    }

    render() {
        return (
            <Fragment>
                <div className='table-responsive'>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Nomor</th>
                                <th>Tanggal</th>
                                <th>Jumlah Invoice</th>
                                <th>Pembayaran</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data.map((item, index) => {
                                    return (
                                        <tr onClick={() => this.handleEdit(item.DocNumber)} key={index}>
                                            <td>{item.DocNumber}</td>
                                            <td>{item.DocDate}</td>
                                            <td>{item.CT}</td>
                                            <td>{numberFormat(item.Total)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <button className='btn btn-primary' style={{ position: "absolute", right: "20px", bottom: "80px", borderRadius: "50%" }} onClick={() => this.handleAdd()}>+</button>
            </Fragment>
        )
    }
}

class FormSetoran extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserID: getCookie("SalesID"),
            arrInvoice: [],
            DocNumber: "",
            Total: 0,
            TanggalPembayaran: saiki(),
            DocDateInvoice: saiki(),
            Amount: 0,
            AmountBalance: 0,
            Diskon: 0,
            ChangeImg: "no",
            btnDelete: "none",
            arrInv: [],
            arrPay: [],
            Berkas: "",
            listPelanggan: [],
            CustomerID: "",
            CustomerName: "",
            CoaCode: "",
            listTagihan: [],
            totalTagihan: 0,
            arrBank: [],
            detailSetoran: {},
            Act: "add_setoran",
            DocNumber: "",
            DocID: "",
        }
    }

    async componentDidMount() {
        let ID = this.props.ID;
        let bank = await api("akun_bank", { Token: getCookie("TokenSales"), Token: getCookie("TokenSales") });
        if (bank.status == "true") this.setState({ arrBank: bank.data });
        if (ID != "") {
            let sql = await api("detail_setoran_sales", { DocNumber: ID, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({
                    arrInvoice: sql.data.NoInvoice,
                    Total: sql.data.AmountOpen,
                    DocNumber: sql.data.DocNumber,
                    TanggalPelunasan: sql.data.TanggalPembayaran,
                    DocDateInvoice: sql.data.DocDateInvoice,
                    Pembayaran: sql.data.Amount,
                    btnDelete: "block",
                    arrInv: sql.detail,
                    Berkas: sql.detail[0].Berkas,
                    DocNumber: sql.data.DocNumber
                });
            }
        }
    }

    handlePembayaran(e) {
        let val = e.target.value;
        let total = this.state.AmountOpen - val;
        this.setState({ Amount: val, AmountBalance: total });
    }

    hanldeDiskon(e) {
        let val = e.target.value;
        let total = this.state.AmountOpen - this.state.Amount;
        this.setState({ Diskon: val, AmountBalance: total });
    }

    openFile(e) {
        document.getElementById("labelImg").innerHTML = '';
        let files = e.target.files;
        let file;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            file = files[i];
            reader.onload = (file) => {
                document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                this.setState({ ChangeImg: "yes" });
            }
            reader.readAsDataURL(file)
        }
    }

    async handleDelete() {
        let sql = await api("delete_data_sales", { ID: this.props.ID, DocNumber: this.state.DocNumber, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            pesan("Berhasil Hapus Data");
            App("Setoran");
        } else {
            pesan("Gagal Hapus Data");
        }
    }

    async modalCariPelanggan() {
        let sql = await api("list_pelanggan", { q: "", SalesID: this.state.UserID, Token: getCookie("TokenSales") });
        if (sql.status == "true") this.setState({ listPelanggan: sql.data });
    }

    async cariPelanggan(q) {
        let sql = await api("list_pelanggan", { q: q, SalesID: this.state.UserID, Token: getCookie("TokenSales") });
        if (sql.status == "true") this.setState({ listPelanggan: sql.data });
    }

    handlePilihPelanggan(i) {
        let pelanggan = this.state.listPelanggan[i];
        this.setState({
            CustomerID: pelanggan.ID,
            CustomerName: pelanggan.Name,
            NoInvoice: "",
            AmountOpen: 0,
            Amount: 0,
            Diskon: 0,
            AmountBalance: 0,
            GiroNumber: "",
            TanggalJatuhTempoGiro: saiki(),
            Berkas: "",
            DocDateInvoice: saiki(),
            TanggalPenagihan: saiki(),
            CoaCode: ""
        });
        document.getElementById('btnTutupModalPelanggan').click();
    }

    handlePilihTagihan(i) {
        let tagihan = this.state.listTagihan[i];
        this.setState({
            NoInvoice: tagihan.DocNumber,
            AmountOpen: tagihan.AmountBalance,
            DocDateInvoice: tagihan.DocDateInvoice,
            AmountBalance: 0,
            Amouunt: 0,
            Diskon: 0,
            GiroNumber: "",
            TanggalJatuhTempoGiro: saiki(),
            Berkas: "",
            TanggalPenagihan: saiki()
        });
        document.getElementById('btnTutupModalTagihan').click();
    }

    async modalCariPayment() {
        if (this.state.CustomerID != "") {
            let sql = await api("arap_list", { CardID: this.state.CustomerID, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({ listTagihan: sql.data });
            }
        } else {
            alert("Silahkan pilih pelanggan terlebih dahulu");
            document.getElementById('edtCustomerName').focus();
            setTimeout(() => {
                document.getElementById('btnTutupModalTagihan').click();
            }, 500);
        }
    }

    async handleInvoiceList(q = "") {
        if (this.state.CustomerID != "") {
            let sql = await api("arap_list", { CardID: this.state.CustomerID, DocNumber: q, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                let totalTagihan = 0;
                for (let data of sql.data) totalTagihan += parseFloat(data.AmountBalance);
                this.setState({ listTagihan: sql.data, totalTagihan: totalTagihan });
            }
        } else {
            alert("Silahkan pilih pelanggan terlebih dahulu");
            document.getElementById('edtCustomerName').focus();
            setTimeout(() => {
                document.getElementById('btnTutupModalTagihan').click();
            }, 500);
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        let sql = await api(this.state.Act, {
            TanggalPelunasan: this.state.TanggalPelunasan,
            DocDateInvoice: this.state.DocDateInvoice,
            NoInvoice: this.state.NoInvoice,
            CustomerID: this.state.CustomerID,
            CustomerName: this.state.CustomerName,
            SalesID: this.state.UserID,
            SalesName: getCookie("SalesName"),
            Amount: this.state.Amount,
            AmountOpen: this.state.AmountOpen,
            AmountBalance: this.state.AmountBalance,
            CoaCode: this.state.CoaCode,
            DocNumber: this.state.DocNumber,
            ID: this.state.DocID,
            Token: getCookie("TokenSales")
        });

        if (sql.status == "true") {
            this.setState({ arrInv: sql.data });
            document.getElementById('btnTutupModalForm').click();
            pesan(sql.message);
        } else {
            pesan(sql.message);
        }
    }

    async handleDeleteInv() {
        let sql = await api("delete_setoran", { ID: this.state.DocID, DocNumber: this.state.DocNumber, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ arrInv: sql.data });
            document.getElementById('btnTutupModalDelete').click();
            pesan(sql.message);
        } else {
            pesan(sql.message);
        }
    }

    handleAddInv() {
        this.setState({
            NoInvoice: "",
            CustomerName: "",
            CustomerID: "",
            AmountOpen: "",
            DocDateInvoice: "",
            AmountBalance: 0,
            Amount: 0,
            Diskon: 0,
            GiroNumber: "",
            TanggalJatuhTempoGiro: saiki(),
            Berkas: "",
            TanggalPenagihan: saiki()
        });
    }

    render() {
        let divImg = (
            <img src={img_upload} width="256px" height="159px" />
        );

        if (this.state.Berkas != "") {
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://penagihanapi.pxa.co.id/";
            divImg = <img src={host + "file/" + this.state.Berkas} width="256px" height="159px" />
        }

        return (
            <Fragment>
                <form id="iniForm" onSubmit={(e) => submitForm(e, { form: "#iniForm", reload: true, act: "simpan_setoran" })} className="needs-validation" noValidate>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <span className='material-icons' onClick={() => App("Setoran")}>chevron_left</span> Setoran
                            </div>
                            <button className='btn btn-sm' type='button' style={{ color: "red", display: this.state.btnDelete }} onClick={() => this.handleDelete()}>
                                <span className='material-icons'>delete</span> Hapus
                            </button>
                        </div>
                    </div>
                    <div className='container main' id='mainContent'>
                        <input type="hidden" value={this.props.ID} name="DocNumber" />
                        <input type="hidden" value={getCookie("SalesID")} name="SalesID" />
                        <input type="hidden" value={getCookie("TokenSales")} name="Token" />
                        <input type="hidden" value={this.state.ChangeImg} name="ChangeImg" />
                        <input type="hidden" value={JSON.stringify([])} name="DataGiro" />
                        <div className='form-group'>
                            <label>Tanggal Penagihan</label>
                            <input type="date" name="TanggalSetor" min={this.state.DocDate} value={this.state.TanggalPembayaran} onChange={(e) => this.setState({ TanggalPembayaran: e.target.value })} className="form-control" placeholder='Tanggal Penagihan' required />
                            <div className='invalid-feedback'>Tanggal Tidak Boleh Lebih Kecil dari tanggal Invoice</div>
                        </div>
                        <p>
                            <button type="button" className='btn btn-primary w-100' onClick={() => this.handleAddInv()} data-bs-target="#modalInvoice" data-bs-toggle="modal">Tambah Invoice</button>
                        </p>
                        <div className='table-responsive'>
                            <table className='table table-bordered' style={{ minWidth: "1000px" }}>
                                <thead>
                                    <tr>
                                        <th width="80px"></th>
                                        <th width="180px">No.Inv</th>
                                        <th width="120px">Saldo Awal</th>
                                        <th width="120px">Pembayaran</th>
                                        <th width="120px">Saldo Akhir</th>
                                        <th>Akun Bank</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.arrInv.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <span className='material-icons' data-bs-toggle="modal" data-bs-target="#modalDelete" onClick={() => this.setState({ DocID: item.ID })}>delete</span>
                                                        <span className='material-icons' data-bs-target="#modalInvoice" data-bs-toggle="modal" onClick={() => this.setState({
                                                            CustomerName: item.CustomerName,
                                                            CustomerID: item.CustomerID,
                                                            DocDateInvoice: item.DocDateInvoice,
                                                            NoInvoice: item.NoInvoice,
                                                            SaldoAwal: item.AmountOpen,
                                                            Pembayaran: item.Amount,
                                                            SaldoAkhir: item.AmountBalance,
                                                            CoaCode: item.CoaCode,
                                                            DocID: item.ID,
                                                            TanggalPenagihan: item.TanggalPembayaran,
                                                            Act: "edit_setoran",
                                                        })}>edit</span>
                                                    </td>
                                                    <td>{item.NoInvoice}</td>
                                                    <td>{numberFormat(item.AmountOpen)}</td>
                                                    <td>{numberFormat(item.Amount)}</td>
                                                    <td>{numberFormat(item.AmountBalance)}</td>
                                                    <td>{item.CoaName}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="form-group">
                            <label>Bukti</label>
                            <div className='image-upload'>
                                <label id="labelImg" htmlFor="image-upload">
                                    {divImg}
                                </label>
                                <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required />
                                <div className="invalid-feedback was-validated">Silahkan pilih Gambar</div>
                            </div>
                        </div>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <button className='btn btn-default w-100' type='submit' disabled={this.state.SaldoAkhir >= 0 ? false : true}>Simpan</button>
                    </div>
                </form>

                <div className="modal fade" id="modalInvoice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.handleSubmit(e)} className='needs-validation' noValidate>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Tambah Invoice</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <div className='form-group'>
                                        <label>Pelanggan</label>
                                        <div className='input-group'>
                                            <input type="text" className='form-control' value={this.state.CustomerName} onFocus={() => this.modalCariPelanggan()} placeholder="Silahkan pilih pelanggan" data-bs-toggle="modal" data-bs-target="#modalCariPelanggan" readOnly />
                                            <button type="button" className='btn btn-primary' onClick={() => this.modalCariPelanggan()} data-bs-toggle="modal" data-bs-target="#modalCariPelanggan"><span className='material-icons'>search</span></button>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Nomor Dokumen</label>
                                        <div className='input-group'>
                                            <input type="text" className='form-control' name="NoInvoice" value={this.state.NoInvoice} onClick={() => this.handleInvoiceList("")} placeholder='Silahkan Pilih Nomor Dokumen' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalTagihan" />
                                            <button className='btn btn-primary' type="button" onClick={() => this.handleInvoiceList("")} data-bs-toggle="modal" data-bs-target="#modalTagihan"><span className='material-icons'>search</span></button>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Saldo Awal</label>
                                        <input type="text" name="SaldoAwal" value={this.state.AmountOpen} className="form-control" placeholder='Saldo Awal' readOnly={true} />
                                    </div>
                                    <div className='form-group'>
                                        <label>Pembayaran</label>
                                        <input type="number" name="Pembayaran" step="0.01" value={this.state.Pembayaran} onChange={(e) => this.handlePembayaran(e)} max={this.state.SaldoAwal} onFocus={(e) => e.target.focus()} className="form-control" placeholder='Jumlah Bayar' required />
                                        <div className='invalid-feedback'>Silahkan Isi Nilai Amount</div>
                                    </div>
                                    <input type="hidden" name="Diskon" value="0" />
                                    <div className='form-group'>
                                        <label>Saldo Akhir</label>
                                        <input type="text" name="SaldoAkhir" value={this.state.AmountBalance} className="form-control" placeholder='Saldo Akhir' readOnly={true} />
                                    </div>
                                    <div className='form-group'>
                                        <label>Akun Bank</label>
                                        <select className='form-select' name="CoaCode" value={this.state.CoaCode} onChange={(e) => this.setState({ CoaCode: e.target.value })} required>
                                            <option value="">Silahkan Pilih Bank</option>
                                            {
                                                this.state.arrBank.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.Code}>{item.Code} - {item.Description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label>Tanggal Penagihan</label>
                                        <input type="date" name="TanggalPelunasan" min={this.state.TanggalPembayaran} value={this.state.TanggalPembayaran} onChange={(e) => this.setState({ TanggalPembayaran: e.target.value })} className="form-control" required />
                                    </div>
                                </div>
                                <div className="modal-footer d-flex">
                                    <button type="button" className="btn btn-secondary" id='btnTutupModalForm' data-bs-dismiss="modal">Tutup</button>
                                    <button type="submit" className="btn btn-primary">Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCariPelanggan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Cari Pelanggan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.cariPelanggan(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Telp</th>
                                                <th>Alamat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listPelanggan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihPelanggan(index)} data-bs-toggle="modal" data-bs-target="#modalInvoice" key={index}>
                                                            <td>{item.Name}</td>
                                                            <td>{item.Telp}</td>
                                                            <td>{item.Address}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalPelanggan' data-bs-dismiss="modal">Tutup</button>
                                <button type="submit" className="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalTagihan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Data Tagihan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Data Tagihan' onChange={(e) => this.handleInvoiceList(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>No Invoice</th>
                                                <th>Tanggal</th>
                                                <th>Usia</th>
                                                <th>PPN</th>
                                                <th>Saldo Awal</th>
                                                <th>Pending</th>
                                                <th>Sisa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listTagihan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihTagihan(index)} data-bs-toggle="modal" data-bs-target="#modalInvoice" key={index}>
                                                            <td>{item.DocNumber}</td>
                                                            <td>{tanggalIndo(item.DocDate)}</td>
                                                            <td>{item.UmurNota}</td>
                                                            <td>{item.TaxType}</td>
                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                            <td>{numberFormat(item.Pending)}</td>
                                                            <td>{numberFormat(item.AmountBalance - item.Pending)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={6}>Total {this.state.listTagihan.length}</td>
                                                <td>{numberFormat(this.state.totalTagihan)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalTagihan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Hapus Invoice</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <h5>Apakah Anda Yakin Akan Menghapus Invoice Ini?</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalDelete' data-bs-dismiss="modal">Tutup</button>
                                <button type="submit" className="btn btn-danger" onClick={(e) => this.handleDeleteInv()}>Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
// END SETORAN


// TUNAI
class Tunai extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    async componentDidMount() {
        let sql = await api("list_tagihan", { SalesID: getCookie("SalesID"), PaymentType: "Tunai", Token: getCookie("TokenSales") });
        if (sql.status == "true") this.setState({ data: sql.data });
    }

    handleAdd(ID = "") {
        dom.render(<FormTunai ID={ID} />);
    }

    handleEdit(ID) {
        dom.render(<FormTunai ID={ID} />)
    }

    render() {
        return (
            <Fragment>
                <div className='table-responsive'>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Pelanggan</th>
                                <th>Invoice</th>
                                <th>PPN</th>
                                <th>Umur</th>
                                <th>Pembayaran</th>
                                <th>Sisa</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data.map((item, index) => {
                                    let Status = <span className='badge text-bg-primary'>Lunas</span>;
                                    if (item.AmountBalance > 0) Status = <span className='badge text-bg-danger'>Kredit</span>;
                                    return (
                                        <tr onClick={() => this.handleEdit(item.ID)} ket={index}>
                                            <td>{item.CustomerName}</td>
                                            <td>{item.NoInvoice}</td>
                                            <td>{item.TaxType}</td>
                                            <td>{item.UmurNota}</td>
                                            <td>{numberFormat(item.Amount)}</td>
                                            <td>{numberFormat(item.AmountBalance)}</td>
                                            <td>{Status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <button className='btn btn-primary' style={{ position: "absolute", right: "20px", bottom: "80px", borderRadius: "50%" }} onClick={() => this.handleAdd()}>+</button>
            </Fragment>
        )
    }
}

class FormTunai extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserID: getCookie("SalesID"),
            NoInvoice: "",
            AmountOpen: "",
            Amount: "",
            Diskon: "0",
            DocDate: saiki(),
            AmountBalance: "",
            CustomerID: "",
            CustomerName: "",
            PaymentType: "Tunai",
            TaxType: "NON PPN",
            Berkas: "",
            ChangeImg: "no",
            DocDateInvoice: saiki(),
            listPelanggan: [],
            listTagihan: [],
            totalSaldoAwal: 0,
            totalTagihanPending: 0,
            totalTagihan: 0,
            btnDelete: "none",
            TanggalPembayaran: saiki(),
            detailTagihan: [],
            SaldoOpen: 0,
            SaldoTertagih: 0,
            SaldoSisa: 0
        }
    }

    async componentDidMount() {
        let ID = this.props.ID;
        if (ID != "") {
            let sql = await api("detail_penagihan", { ID: ID, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({
                    NoInvoice: sql.data.NoInvoice,
                    AmountOpen: sql.data.AmountOpen,
                    Amount: sql.data.Amount,
                    AmountBalance: sql.data.AmountBalance,
                    CustomerID: sql.data.CustomerID,
                    CustomerName: sql.data.CustomerName,
                    Berkas: sql.data.Berkas,
                    DocDateInvoice: sql.data.DocDateInvoice2,
                    btnDelete: "block",
                    TanggalPembayaran: sql.data.TanggalPembayaran2,
                    TaxType: sql.data.TaxType,
                    DocDate: sql.data.DocDateInvoice2
                });
            }
        }
    }

    async handleInvoiceList(q = "") {
        if (this.state.CustomerID != "") {
            let sql = await api("arap_list", { CardID: this.state.CustomerID, DocNumber: q, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                let totalTagihan = 0,
                    totalSaldoAwal = 0,
                    totalTagihanPending = 0;
                for (let data of sql.data) {
                    totalSaldoAwal += parseFloat(data.AmountBalance);
                    totalTagihanPending += parseFloat(data.Pending);
                    totalTagihan += parseFloat(data.AmountBalance - data.Pending);
                }
                this.setState({
                    listTagihan: sql.data,
                    totalSaldoAwal: totalSaldoAwal,
                    totalTagihanPending: totalTagihanPending,
                    totalTagihan: totalTagihan
                });
            }
        } else {
            alert("Silahkan pilih pelanggan terlebih dahulu");
            document.getElementById('edtCustomerName').focus();
            setTimeout(() => {
                document.getElementById('btnTutupModalTagihan').click();
            }, 500);
        }
    }

    async cariPelanggan(q) {
        let sql = await api("list_pelanggan", { q: q, SalesID: this.state.UserID, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ listPelanggan: sql.data });
        }
    }

    handlePilihPelanggan(i) {
        let pelanggan = this.state.listPelanggan[i];
        this.setState({
            CustomerID: pelanggan.ID,
            CustomerName: pelanggan.Name,
            NoInvoice: "",
            AmountOpen: 0,
            Amount: 0,
            Diskon: 0,
            AmountBalance: 0,
            Berkas: "",
            DocDateInvoice: saiki(),
            TanggalPembayaran: saiki()
        });
        document.getElementById('btnTutupModalPelanggan').click();
    }

    async handlePilihTagihan(i) {
        let tagihan = this.state.listTagihan[i];
        let sql = await api("detail_pembayaran_sales", { NoInvoice: tagihan.DocNumber, Token: getCookie("TokenSales") });
        console.log(sql);
        if (sql.status == "true") {
            let Total = 0;
            if (sql.data.length > 0) {
                if (sql.setting == 0) {
                    for (let data of sql.data) Total += parseFloat(data.Amount);
                    this.setState({
                        detailTagihan: sql.data,
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance - Total,
                        SaldoOpen: tagihan.AmountBalance,
                        SaldoTertagih: Total,
                        SaldoSisa: tagihan.AmountBalance - Total,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        Diskon: 0,
                        GiroNumber: "",
                        GiroTermDate: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    const myModal = new window.bootstrap.Modal('#modalDetailTagihan', {});
                    myModal.show('#modalDetailTagihan');
                } else {
                    alert("Masih ada penagihan yang belum di proses");
                }
            } else {
                this.setState({
                    NoInvoice: tagihan.DocNumber,
                    AmountOpen: tagihan.AmountBalance,
                    DocDateInvoice: tagihan.DocDateInvoice,
                    TaxType: tagihan.TaxType,
                    AmountBalance: 0,
                    Amount: 0,
                    Diskon: 0,
                    GiroNumber: "",
                    TanggalJatuhTempoGiro: saiki(),
                    Berkas: "",
                    TanggalPembayaran: saiki(),
                    DocDate: tagihan.DocDateInvoice
                });
                document.getElementById('btnTutupModalTagihan').click();
            }
        }
    }

    handlePilihTrans2() {
        document.getElementById('btnTutupModalDetailPembayaran').click();
        document.getElementById('btnTutupModalTagihan').click();
    }

    handleCancelPilihTrans() {
        this.setState({
            NoInvoice: "",
            TaxType: "NON PPN",
            AmountOpen: 0,
            DocDateInvoice: saiki(),
            AmountBalance: 0,
            SaldoOpen: 0,
            SaldoSisa: 0,
            SaldoTertagih: 0,
            Amount: 0,
            Diskon: 0,
            GiroNumber: "",
            GiroTermDate: saiki(),
            Berkas: "",
            TanggalPembayaran: saiki()
        });
    }

    handlePembayaran(e) {
        let val = e.target.value;
        let total = this.state.AmountOpen - val;
        this.setState({ Amount: val, AmountBalance: total });
    }

    hanldeDiskon(e) {
        let val = e.target.value;
        let total = this.state.AmountOpen - this.state.Amount - val;
        this.setState({ Diskon: val, AmountBalance: total });
    }

    openFile(e) {
        document.getElementById("labelImg").innerHTML = '';
        let files = e.target.files;
        let file;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            file = files[i];
            reader.onload = (file) => {
                document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                this.setState({ ChangeImg: "yes" });
            }
            reader.readAsDataURL(file)
        }
    }

    async handleDelete() {
        let sql = await api("delete_data_sales", { ID: this.props.ID, DocNumber: this.state.DocNumber, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            pesan("Berhasil Hapus Data");
            App("Tunai");
        } else {
            pesan("Gagal Hapus Data");
        }
    }

    render() {
        let divImg = (
            <img src={img_upload} width="256px" height="159px" />
        );

        if (this.state.Berkas != "") {
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://penagihanapi.pxa.co.id/";
            divImg = <img src={host + "file/" + this.state.Berkas} width="256px" height="159px" />
        }

        return (
            <Fragment>
                <form id="iniForm" onSubmit={(e) => submitForm(e, { form: "#iniForm", reload: true, act: this.props.ID == "" ? "insert_doc" : "edit_doc" })} className="needs-validation" noValidate>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <span className='material-icons' onClick={() => App("Tunai")}>chevron_left</span> Tunai
                            </div>
                            <button className='btn btn-sm' type='button' style={{ color: "red", display: this.state.btnDelete }} onClick={() => this.handleDelete()}>
                                <span className='material-icons'>delete</span> Hapus
                            </button>
                        </div>
                    </div>
                    <div className='container main' id='mainContent'>
                        <input type="hidden" value={this.props.ID} name="ID" />
                        <input type="hidden" value={getCookie("SalesID")} name="SalesID" />
                        <input type="hidden" value={getCookie("SalesName")} name="SalesName" />
                        <input type="hidden" value={getCookie("TokenSales")} name="Token" />
                        <input type="hidden" value="Tunai" name="PaymentType" />
                        <input type="hidden" value={saiki()} name="GiroTermDate" />
                        <input type="hidden" value={this.state.CustomerID} name="CustomerID" />
                        <input type="hidden" value={this.state.ChangeImg} name="ChangeImg" />
                        <input type="hidden" value={this.state.DocDateInvoice} name="DocDateInvoice" />
                        <input type="hidden" value={this.state.TaxType} name="TaxType" />
                        <div className='form-group'>
                            <label>Nama Pelanggan</label>
                            <div className='input-group'>
                                <input type="text" className='form-control' id='edtCustomerName' name="CustomerName" value={this.state.CustomerName} onFocus={() => this.cariPelanggan("")} placeholder='Silahkan Pilih Nama Pelanggan' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalPelanggan" />
                                <button className='btn btn-primary' type="button" onClick={() => this.cariPelanggan("")} data-bs-toggle="modal" data-bs-target="#modalPelanggan"><span className='material-icons'>search</span></button>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Nomor Dokumen</label>
                            <div className='input-group'>
                                <input type="text" className='form-control' name="NoInvoice" value={this.state.NoInvoice} onFocus={() => this.handleInvoiceList("")} placeholder='Silahkan Pilih Nomor Dokumen' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalInvoice" />
                                <button className='btn btn-primary' type="button" onClick={() => this.handleInvoiceList("")} data-bs-toggle="modal" data-bs-target="#modalInvoice"><span className='material-icons'>search</span></button>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Saldo Awal</label>
                            <input type="text" name="AmountOpen" value={this.state.AmountOpen} className="form-control" placeholder='Saldo Awal' readOnly={true} />
                        </div>
                        <div className='form-group'>
                            <label>Pembayaran</label>
                            <input type="number" name="Amount" step="0.01" value={this.state.Amount} onChange={(e) => this.handlePembayaran(e)} max={this.state.AmountOpen} className="form-control" placeholder='Jumlah Bayar' required />
                            <div className='invalid-feedback'>Silahkan Isi Nilai Pembayaran</div>
                        </div>
                        <input type="hidden" name="Diskon" value={this.state.Diskon} onChange={(e) => this.hanldeDiskon(e)} max={this.state.SaldoAwal} className="form-control" placeholder='Diskon Jika Ada' />
                        {/* <div className='form-group'>
                            <label>Diskon</label>
                        </div> */}
                        <div className='form-group'>
                            <label>Saldo Akhir</label>
                            <input type="text" name="AmountBalance" value={this.state.AmountBalance} className="form-control" placeholder='Saldo Akhir' readOnly={true} />
                        </div>
                        <div className='form-group'>
                            <label>Tanggal Penagihan</label>
                            <input type="date" name="TanggalPembayaran" min={this.state.DocDate} value={this.state.TanggalPembayaran} onChange={(e) => this.setState({ TanggalPembayaran: e.target.value })} className="form-control" placeholder='Tanggal Penagihan' required />
                            <div className="text-danger">Tanggal Tidak boleh dibawah tanggal invoice</div>
                        </div>
                        <div className="form-group">
                            <label>Bukti</label>
                            <div className='image-upload'>
                                <label id="labelImg" htmlFor="image-upload">
                                    {divImg}
                                </label>
                                <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} />
                                <div className="invalid-feedback">Silahkan pilih Gambar</div>
                            </div>
                        </div>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <button className='btn btn-default w-100' type='submit' disabled={this.state.AmountBalance >= 0 ? false : true}>Simpan</button>
                    </div>
                </form>

                <div className="modal fade" id="modalPelanggan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Data Pelanggan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.cariPelanggan(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Telp</th>
                                                <th>Alamat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listPelanggan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihPelanggan(index)} key={index}>
                                                            <td>{item.Name}</td>
                                                            <td>{item.Telp}</td>
                                                            <td>{item.Address}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalPelanggan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalInvoice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Data Tagihan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Data Tagihan' onChange={(e) => this.handleInvoiceList(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>No Invoice</th>
                                                <th>Tanggal</th>
                                                <th>Usia</th>
                                                <th>PPN</th>
                                                <th>Saldo Awal</th>
                                                <th>Pending</th>
                                                <th>Sisa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listTagihan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihTagihan(index)} key={index}>
                                                            <td>{item.DocNumber}</td>
                                                            <td>{tanggalIndo(item.DocDate)}</td>
                                                            <td>{item.UmurNota}</td>
                                                            <td>{item.TaxType}</td>
                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                            <td>{numberFormat(item.Pending)}</td>
                                                            <td>{numberFormat(item.AmountBalance - item.Pending)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={4}>Total {this.state.listTagihan.length}</td>
                                                <td>{numberFormat(this.state.totalSaldoAwal)}</td>
                                                <td>{numberFormat(this.state.totalTagihanPending)}</td>
                                                <td>{numberFormat(this.state.totalTagihan)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalTagihan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetailTagihan" tabIndex="-1" aria-labelledby="modalDetailTagihan" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Detail Setoran</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnTutupModalDetailPembayaran'></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <div className='table-responsive'>
                                    <h6>Detail Penagihan Tertunda</h6>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Tanggal</th>
                                                <th>Pembayaran</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.detailTagihan.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                            <td>{numberFormat(item.Amount)}</td>
                                                            <td>{item.State}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={2}><b>Total</b></td>
                                                <td><b>{numberFormat(this.state.SaldoTertagih)}</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <h6>Rincian</h6>
                                    <table className='table table-striped'>
                                        <tbody>
                                            <tr>
                                                <td>Saldo Awal</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoOpen)}</td>
                                            </tr>
                                            <tr>
                                                <td>Penagihan yang Tertunda</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoTertagih)}</td>
                                            </tr>
                                            <tr>
                                                <td>Sisa Tagihan</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoSisa)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => this.handleCancelPilihTrans()}>Batal</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.handlePilihTrans2()} disabled={this.state.AmountOpen > 0 ? false : true}>Pilih</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
// END TUNAI

// TRANSFER
class Transfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    async componentDidMount() {
        let sql = await api("list_tagihan", { SalesID: getCookie("SalesID"), PaymentType: "Transfer", Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ data: sql.data });
        }
    }

    handleAdd(ID = "") {
        dom.render(<FormTransfer ID={ID} />);
    }

    handleEdit(ID) {
        dom.render(<FormTransfer ID={ID} />)
    }

    render() {
        return (
            <Fragment>
                <div className='table-responsive'>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Pelanggan</th>
                                <th>Invoice</th>
                                <th>PPN</th>
                                <th>Umur</th>
                                <th>Pembayaran</th>
                                <th>Sisa</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data.map((item, index) => {
                                    let Status = <span className='badge text-bg-primary'>Lunas</span>;
                                    if (item.AmountBalance > 0) Status = <span className='badge text-bg-danger'>Kredit</span>;
                                    return (
                                        <tr onClick={() => this.handleEdit(item.ID)} key={index}>
                                            <td>{item.CustomerName}</td>
                                            <td>{item.NoInvoice}</td>
                                            <td>{item.TaxType}</td>
                                            <td>{item.UmurNota}</td>
                                            <td>{numberFormat(item.Amount)}</td>
                                            <td>{numberFormat(item.AmountBalance)}</td>
                                            <td>{Status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <button className='btn btn-primary' style={{ position: "absolute", right: "20px", bottom: "80px", borderRadius: "50%" }} onClick={() => this.handleAdd()}>+</button>
                <p style={{ marginTop: "100px" }}></p>
            </Fragment>
        )
    }
}

class FormTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserID: getCookie("SalesID"),
            NoInvoice: "",
            DocNumber: "",
            AmountOpen: "0",
            Amount: "0",
            Diskon: "0",
            AmountBalance: "0",
            CustomerID: "",
            CustomerName: "",
            PaymentType: "Transfer",
            TaxType: "NON PPN",
            GiroNumber: "",
            GiroTermDate: "",
            Berkas: "",
            ChangeImg: "no",
            TanggalPembayaran: saiki(),
            DocDateInvoice: saiki(),
            listTagihan: [],
            totalSaldoAwal: 0,
            totalTagihanPending: 0,
            totalTagihan: 0,
            listPelanggan: [],
            btnDelete: "none",
            arrBank: [],
            CoaCode: "",
            detailTagihan: [],
            SaldoOpen: 0,
            SaldoTertagih: 0,
            SaldoSisa: 0,
            DocDate: saiki()
        }
    }

    async componentDidMount() {
        let ID = this.props.ID;
        let bank = await api("akun_bank", { Token: getCookie("TokenSales") });
        if (bank.status == "true") this.setState({ arrBank: bank.data });
        if (ID != "") {
            let sql = await api("detail_penagihan", { ID: ID, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({
                    NoInvoice: sql.data.NoInvoice,
                    DocNumber: sql.data.DocNumber,
                    AmountOpen: sql.data.AmountOpen,
                    Amount: sql.data.Amount,
                    Diskon: sql.data.Diskon,
                    AmountBalance: sql.data.AmountBalance,
                    CustomerID: sql.data.CustomerID,
                    CustomerName: sql.data.CustomerName,
                    GiroNumber: sql.data.GiroNumber,
                    GiroTermDate: sql.data.GiroTermDate,
                    TanggalPembayaran: sql.data.TanggalPembayaran2,
                    Berkas: sql.data.Berkas,
                    DocDateInvoice: sql.data.DocDateInvoice2,
                    btnDelete: "block",
                    CoaCode: sql.payment.CoaCode,
                    TaxType: sql.data.TaxType,
                    DocDate: sql.data.DocDateInvoice2
                });
            }
        }
    }

    async handleInvoiceList(q = "") {
        if (this.state.CustomerID != "") {
            let sql = await api("arap_list", { CardID: this.state.CustomerID, DocNumber: q, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                let totalTagihan = 0,
                    totalSaldoAwal = 0,
                    totalTagihanPending = 0;
                for (let data of sql.data) {
                    totalSaldoAwal += parseFloat(data.AmountBalance);
                    totalTagihanPending += parseFloat(data.Pending);
                    totalTagihan += parseFloat(data.AmountBalance - data.Pending);
                }
                this.setState({
                    listTagihan: sql.data,
                    totalSaldoAwal: totalSaldoAwal,
                    totalTagihanPending: totalTagihanPending,
                    totalTagihan: totalTagihan
                });
            }
        } else {
            alert("Silahkan pilih pelanggan terlebih dahulu");
            document.getElementById('edtCustomerName').focus();
            setTimeout(() => {
                document.getElementById('btnTutupModalTagihan').click();
            }, 500);
        }
    }

    async cariPelanggan(q) {
        let sql = await api("list_pelanggan", { q: q, SalesID: this.state.UserID, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ listPelanggan: sql.data });
        }
    }

    handlePilihPelanggan(i) {
        let pelanggan = this.state.listPelanggan[i];
        this.setState({
            CustomerID: pelanggan.ID,
            CustomerName: pelanggan.Name,
            NoInvoice: "",
            SaldoAwal: 0,
            Pembayaran: 0,
            Diskon: 0,
            SaldoAkhir: 0,
            GiroNumber: "",
            TanggalJatuhTempoGiro: saiki(),
            Berkas: "",
            DocDateInvoice: saiki(),
            TanggalPenagihan: saiki(),
            CoaCode: ""
        });
        document.getElementById('btnTutupModalPelanggan').click();
    }

    async handlePilihTagihan(i) {
        let tagihan = this.state.listTagihan[i];
        let sql = await api("detail_pembayaran_sales", { NoInvoice: tagihan.DocNumber, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            let Total = 0;
            if (sql.data.length > 0) {
                if (sql.setting == 0) {
                    for (let data of sql.data) Total += parseFloat(data.Amount);
                    this.setState({
                        detailTagihan: sql.data,
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance - Total,
                        SaldoOpen: tagihan.AmountBalance,
                        SaldoTertagih: Total,
                        SaldoSisa: tagihan.AmountBalance - Total,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        Diskon: 0,
                        GiroNumber: "",
                        GiroTermDate: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    const myModal = new window.bootstrap.Modal('#modalDetailTagihan', {})
                    myModal.show('#modalDetailTagihan');
                } else {
                    alert("Masih ada tagihan yang pending");
                }
            } else {
                this.setState({
                    NoInvoice: tagihan.DocNumber,
                    AmountOpen: tagihan.AmountBalance,
                    DocDateInvoice: tagihan.DocDateInvoice,
                    TaxType: tagihan.TaxType,
                    AmountBalance: 0,
                    Amount: 0,
                    Diskon: 0,
                    GiroNumber: "",
                    GiroTermDate: saiki(),
                    Berkas: "",
                    TanggalPembayaran: saiki(),
                    DocDate: tagihan.DocDateInvoice
                });
                document.getElementById('btnTutupModalTagihan').click();
            }
        }
    }

    handlePilihTrans2() {
        document.getElementById('btnTutupModalDetailPembayaran').click();
        document.getElementById('btnTutupModalTagihan').click();
    }

    handleCancelPilihTrans() {
        this.setState({
            NoInvoice: "",
            AmountOpen: 0,
            DocDateInvoice: saiki(),
            AmountBalance: 0,
            SaldoOpen: 0,
            SaldoSisa: 0,
            SaldoTertagih: 0,
            Amount: 0,
            Diskon: 0,
            GiroNumber: "",
            GiroTermDate: saiki(),
            Berkas: "",
            TanggalPembayaran: saiki(),
            TaxType: "NON PPN"
        });
    }

    handlePembayaran(e) {
        let val = e.target.value;
        let total = this.state.AmountOpen - val;
        this.setState({ Amount: val, AmountBalance: total });
    }

    hanldeDiskon(e) {
        let val = e.target.value;
        let total = this.state.AmountOpen - this.state.Amount - val;
        this.setState({ Diskon: val, AmountBalance: total });
    }

    openFile(e) {
        document.getElementById("labelImg").innerHTML = '';
        let files = e.target.files;
        let file;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            file = files[i];
            reader.onload = (file) => {
                document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                this.setState({ ChangeImg: "yes" });
            }
            reader.readAsDataURL(file)
        }
    }

    async handleDelete() {
        let sql = await api("delete_data_sales", { ID: this.props.ID, DocNumber: this.state.DocNumber, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            pesan("Berhasil Hapus Data");
            App("Transfer");
        } else {
            pesan("Gagal Hapus Data");
        }
    }

    render() {
        let divImg = (
            <img src={img_upload} width="256px" height="159px" />
        );
        if (this.state.Berkas != "") {
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://penagihanapi.pxa.co.id/";
            divImg = <img src={host + "file/" + this.state.Berkas} width="256px" height="159px" />
        }
        return (
            <Fragment>
                <form id="iniForm" onSubmit={(e) => submitForm(e, { form: "#iniForm", reload: true, act: this.props.ID == "" ? "insert_doc" : "edit_doc" })} className="needs-validation" noValidate>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <span className='material-icons' onClick={() => App("Transfer")}>chevron_left</span> Input
                            </div>
                            <button className='btn btn-sm' type='button' style={{ color: "red", display: this.state.btnDelete }} onClick={() => this.handleDelete()}>
                                <span className='material-icons'>delete</span> Hapus
                            </button>
                        </div>
                    </div>
                    <div className='container main' id='mainContent'>
                        <input type="hidden" value={this.props.ID} name="ID" />
                        <input type="hidden" value={getCookie("TokenSales")} name="Token" />
                        <input type="hidden" value={this.state.UserID} name="SalesID" />
                        <input type="hidden" value={getCookie("SalesName")} name="SalesName" />
                        <input type="hidden" value="Transfer" name="PaymentType" />
                        <input type="hidden" value={this.state.CustomerID} name="CustomerID" />
                        <input type="hidden" value={this.state.ChangeImg} name="ChangeImg" />
                        <input type="hidden" value={this.state.DocDateInvoice} name="DocDateInvoice" />
                        <input type="hidden" value={this.state.TaxType} name="TaxType" />
                        <input type="hidden" name="Diskon" value="0" />
                        <input type="hidden" value={JSON.stringify([])} name="DataGiro" />
                        <input type="hidden" value={saiki()} name="GiroTermDate" />

                        <div className='form-group'>
                            <label>Nama Pelanggan</label>
                            <div className='input-group'>
                                <input type="text" className='form-control' id='edtCustomerName' name="CustomerName" value={this.state.CustomerName} onFocus={() => this.cariPelanggan("")} placeholder='Silahkan Pilih Nama Pelanggan' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalPelanggan" />
                                <button className='btn btn-primary' type="button" onClick={() => this.cariPelanggan("")} data-bs-toggle="modal" data-bs-target="#modalPelanggan"><span className='material-icons'>search</span></button>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Nomor Dokumen</label>
                            <div className='input-group'>
                                <input type="text" className='form-control' name="NoInvoice" value={this.state.NoInvoice} onFocus={() => this.handleInvoiceList("")} placeholder='Silahkan Pilih Nomor Dokumen' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalInvoice" />
                                <button className='btn btn-primary' type="button" onClick={() => this.handleInvoiceList("")} data-bs-toggle="modal" data-bs-target="#modalInvoice"><span className='material-icons'>search</span></button>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Saldo Awal</label>
                            <input type="text" name="AmountOpen" value={this.state.AmountOpen} className="form-control" placeholder='Saldo Awal' readOnly={true} />
                        </div>
                        <div className='form-group'>
                            <label>Pembayaran</label>
                            <input type="number" name="Amount" step="0.01" value={this.state.Amount} onChange={(e) => this.handlePembayaran(e)} max={this.state.AmountOpen} className="form-control" placeholder='Jumlah Bayar' required />
                            <div className='invalid-feedback'>Silahkan Isi Nilai Pembayaran</div>
                        </div>
                        {/* <div className='form-group'>
                            <label>Diskon</label>
                        </div> */}
                        <div className='form-group'>
                            <label>Saldo Akhir</label>
                            <input type="text" name="AmountBalance" value={this.state.AmountBalance} className="form-control" placeholder='Saldo Akhir' readOnly={true} />
                        </div>
                        <div className='form-group'>
                            <label>Tanggal Transfer</label>
                            <input type="date" name="TanggalPembayaran" min={this.state.DocDate} value={this.state.TanggalPembayaran} onChange={(e) => this.setState({ TanggalPembayaran: e.target.value })} className="form-control" required />
                            <div className="text-danger">Tanggal Tidak boleh dibawah tanggal invoice</div>
                        </div>
                        <div className='form-group'>
                            <label>Akun Bank</label>
                            <select className='form-select' name="CoaCode" value={this.state.CoaCode} onChange={(e) => this.setState({ CoaCode: e.target.value })} required>
                                <option value="">Silahkan Pilih Bank</option>
                                {
                                    this.state.arrBank.map((item, index) => {
                                        return (
                                            <option key={index} value={item.Code}>{item.Code} - {item.Description}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Bukti</label>
                            <div className='image-upload'>
                                <label id="labelImg" htmlFor="image-upload">
                                    {divImg}
                                </label>
                                <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} />
                                <div className="invalid-feedback">Silahkan pilih Gambar</div>
                            </div>
                        </div>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <button className='btn btn-default w-100' type='submit' disabled={this.state.AmountBalance >= 0 ? false : true}>Simpan</button>
                    </div>
                </form>

                <div className="modal fade" id="modalPelanggan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Data Pelanggan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.cariPelanggan(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Telp</th>
                                                <th>Alamat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listPelanggan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihPelanggan(index)} key={index}>
                                                            <td>{item.Name}</td>
                                                            <td>{item.Telp}</td>
                                                            <td>{item.Address}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalPelanggan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalInvoice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Data Tagihan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Data Tagihan' onChange={(e) => this.handleInvoiceList(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>No Invoice</th>
                                                <th>Tanggal</th>
                                                <th>Usia</th>
                                                <th>PPN</th>
                                                <th>Saldo Awal</th>
                                                <th>Pending</th>
                                                <th>Sisa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listTagihan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihTagihan(index)} key={index}>
                                                            <td>{item.DocNumber}</td>
                                                            <td>{tanggalIndo(item.DocDate)}</td>
                                                            <td>{item.UmurNota}</td>
                                                            <td>{item.TaxType}</td>
                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                            <td>{numberFormat(item.Pending)}</td>
                                                            <td>{numberFormat(item.AmountBalance - item.Pending)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={4}>Total {this.state.listTagihan.length}</td>
                                                <td>{numberFormat(this.state.totalSaldoAwal)}</td>
                                                <td>{numberFormat(this.state.totalTagihanPending)}</td>
                                                <td>{numberFormat(this.state.totalTagihan)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalTagihan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetailTagihan" tabIndex="-1" aria-labelledby="modalDetailTagihan" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Detail Setoran</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnTutupModalDetailPembayaran'></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <div className='table-responsive'>
                                    <h6>Detail Penagihan Tertunda</h6>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Tanggal</th>
                                                <th>Pembayaran</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.detailTagihan.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                            <td>{numberFormat(item.Amount)}</td>
                                                            <td>{item.State}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={2}><b>Total</b></td>
                                                <td><b>{numberFormat(this.state.SaldoTertagih)}</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <h6>Rincian</h6>
                                    <table className='table table-striped'>
                                        <tbody>
                                            <tr>
                                                <td>Saldo Awal</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoOpen)}</td>
                                            </tr>
                                            <tr>
                                                <td>Penagihan yang Tertunda</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoTertagih)}</td>
                                            </tr>
                                            <tr>
                                                <td>Sisa Tagihan</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoSisa)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => this.handleCancelPilihTrans()}>Batal</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.handlePilihTrans2()} disabled={this.state.AmountOpen > 0 ? false : true}>Pilih</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
// END TRANSFER

// GIRO
class Giro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    async componentDidMount() {
        let sql = await api("list_tagihan", { SalesID: getCookie("SalesID"), PaymentType: "Giro", Token: getCookie("TokenSales") });
        console.log(sql);
        if (sql.status == "true") {
            this.setState({ data: sql.data });
        }
    }

    handleAdd(ID = "") {
        dom.render(<FormGiro ID={ID} />);
    }

    handleEdit(ID) {
        dom.render(<FormGiro ID={ID} />)
    }

    render() {
        return (
            <Fragment>
                <div className='table-responsive'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Pelanggan</th>
                                <th>Invoice</th>
                                <th>Umur</th>
                                <th>PPN</th>
                                <th>Pembayaran</th>
                                <th>Sisa</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data.map((item, index) => {
                                    let Status = <span className='badge text-bg-primary'>Lunas</span>;
                                    if (item.SaldoAkhir > 0) Status = <span className='badge text-bg-danger'>Kredit</span>;
                                    return (
                                        <tr onClick={() => this.handleEdit(item.ID)} key={index}>
                                            <td>{item.CustomerName}</td>
                                            <td>{item.NoInvoice}</td>
                                            <td>{item.UmurNota}</td>
                                            <td>{item.TaxType}</td>
                                            <td>{numberFormat(item.Amount)}</td>
                                            <td>{numberFormat(item.AmountBalance)}</td>
                                            <td>{Status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <button className='btn btn-primary' style={{ position: "absolute", right: "20px", bottom: "80px", borderRadius: "50%" }} onClick={() => this.handleAdd()}>+</button>
                <p style={{ marginTop: "100px" }}></p>
            </Fragment>
        )
    }
}

class FormGiro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserID: getCookie("SalesID"),
            NoInvoice: "",
            DocNumber: "",
            AmountOpen: "0",
            Amount: "0",
            Diskon: "0",
            AmountBalance: "0",
            CustomerID: "",
            CustomerName: "",
            PaymentType: "Giro",
            TaxType: "NON PPN",
            GiroNumber: "",
            GiroTermDate: saiki(),
            Berkas: "",
            ChangeImg: "no",
            TanggalPembayaran: saiki(),
            DocDateInvoice: saiki(),
            listTagihan: [],
            totalSaldoAwal: 0,
            totalTagihanPending: 0,
            totalTagihan: 0,
            listPelanggan: [],
            btnDelete: "none",
            tempGiroNumber: "",
            tempGiroTermDate: saiki(),
            tempGiroNominal: 0,
            tempIndex: "tidak",
            tempDataGiro: [],
            totalGiro: 0,
            arrBank: [],
            tempCoaCode: "",
            tempCoaName: "",
            tempCoaID: "",
            tempDocID: "",
            tempID: "",
            tempData: [],
            formMode: "add",
            Berkas: "",
            detailTagihan: [],
            SaldoOpen: 0,
            SaldoTertagih: 0,
            SaldoSisa: 0,
            DocDate: saiki()
        }
    }

    async componentDidMount() {
        let ID = this.props.ID;
        let bank = await api("akun_bank", { Token: getCookie("TokenSales") });
        if (bank.status == "true") this.setState({ arrBank: bank.data });
        let tmp = await api("get_temp_giro", { SalesID: this.state.UserID, Token: getCookie("TokenSales") });
        if (tmp.status == "true") this.setState({ tempDocID: tmp.data });
        if (ID != "") {
            let sql = await api("detail_penagihan", { ID: ID, Token: getCookie("TokenSales") });
            let totalGiro = 0;
            for (let giro of sql.giro) totalGiro += parseFloat(giro.GiroNominal);
            if (sql.status == "true") {
                this.setState({
                    NoInvoice: sql.data.NoInvoice,
                    AmountOpen: sql.data.AmountOpen,
                    Amount: sql.data.Amount,
                    AmountBalance: sql.data.AmountBalance,
                    CustomerID: sql.data.CustomerID,
                    CustomerName: sql.data.CustomerName,
                    GiroNumber: sql.data.GiroNumber,
                    GiroTermDate: sql.data.GiroTermDate2,
                    TanggalPembayaran: sql.data.TanggalPelmbayaran2,
                    Berkas: sql.data.Berkas,
                    DocDateInvoice: sql.data.DocDateInvoice2,
                    TaxType: sql.data.TaxType,
                    btnDelete: "block",
                    tempDataGiro: sql.giro,
                    totalGiro: totalGiro,
                    formMode: "edit",
                    DocDate: sql.data.DocDateInvoice2
                });
            }
        }
    }

    async handleInvoiceList(q = "") {
        if (this.state.CustomerID != "") {
            let sql = await api("arap_list", { CardID: this.state.CustomerID, DocNumber: q, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                let totalTagihan = 0,
                    totalSaldoAwal = 0,
                    totalTagihanPending = 0;
                for (let data of sql.data) {
                    totalSaldoAwal += parseFloat(data.AmountBalance);
                    totalTagihanPending += parseFloat(data.Pending);
                    totalTagihan += parseFloat(data.AmountBalance - data.Pending);
                }
                this.setState({
                    listTagihan: sql.data,
                    totalSaldoAwal: totalSaldoAwal,
                    totalTagihanPending: totalTagihanPending,
                    totalTagihan: totalTagihan
                });
            }
        } else {
            alert("Silahkan pilih pelanggan terlebih dahulu");
            document.getElementById('edtCustomerName').focus();
            setTimeout(() => {
                document.getElementById('btnTutupModalTagihan').click();
            }, 500);
        }
    }

    async cariPelanggan(q) {
        let sql = await api("list_pelanggan", { q: q, SalesID: this.state.UserID, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ listPelanggan: sql.data });
        }
    }

    handlePilihPelanggan(i) {
        let pelanggan = this.state.listPelanggan[i];
        this.setState({
            CustomerID: pelanggan.ID,
            CustomerName: pelanggan.Name,
            NoInvoice: "",
            AmountOpen: 0,
            SaldoAwal: 0,
            Pembayaran: 0,
            Diskon: 0,
            SaldoAkhir: 0,
            GiroNumber: "",
            TanggalJatuhTempoGiro: saiki(),
            Berkas: "",
            DocDateInvoice: saiki(),
            TanggalPenagihan: saiki()
        });
        document.getElementById('btnTutupModalPelanggan').click();
    }

    async handlePilihTagihan(i) {
        let tagihan = this.state.listTagihan[i];
        let sql = await api("detail_pembayaran_sales", { NoInvoice: tagihan.DocNumber, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            let Total = 0;
            if (sql.data.length > 0) {
                if (sql.setting == 0) {

                    for (let data of sql.data) Total += parseFloat(data.Amount);
                    this.setState({
                        detailTagihan: sql.data,
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance - Total,
                        SaldoOpen: tagihan.AmountBalance,
                        SaldoTertagih: Total,
                        SaldoSisa: tagihan.AmountBalance - Total,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        Diskon: 0,
                        GiroNumber: "",
                        GiroTermDate: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    const myModal = new window.bootstrap.Modal('#modalDetailTagihan', {})
                    myModal.show('#modalDetailTagihan');
                } else {
                    alert("Masih ada tagihan yang pending");
                }
            } else {
                this.setState({
                    NoInvoice: tagihan.DocNumber,
                    AmountOpen: tagihan.AmountBalance,
                    DocDateInvoice: tagihan.DocDateInvoice,
                    TaxType: tagihan.TaxType,
                    AmountBalance: 0,
                    Amount: 0,
                    GiroNumber: "",
                    GiroTermDate: saiki(),
                    Berkas: "",
                    TanggalPembayaran: saiki(),
                    DocDate: tagihan.DocDateInvoice
                });
                document.getElementById('btnTutupModalTagihan').click();
            }
        }
    }

    handlePilihTrans2() {
        document.getElementById('btnTutupModalDetailPembayaran').click();
        document.getElementById('btnTutupModalTagihan').click();
    }

    handleCancelPilihTrans() {
        this.setState({
            NoInvoice: "",
            AmountOpen: 0,
            DocDateInvoice: saiki(),
            AmountBalance: 0,
            SaldoOpen: 0,
            SaldoSisa: 0,
            SaldoTertagih: 0,
            Amount: 0,
            Diskon: 0,
            GiroNumber: "",
            GiroTermDate: saiki(),
            Berkas: "",
            TanggalPembayaran: saiki()
        });
    }

    handlePembayaran(e) {
        let val = e.target.value;
        let total = this.state.AmountOpen - val;
        this.setState({ Amount: val, AmountBalance: total });
    }

    hanldeDiskon(e) {
        let val = e.target.value;
        let total = this.state.SaldoAwal - this.state.Pembayaran - val;
        this.setState({ Diskon: val, SaldoAkhir: total });
    }

    openFile(e) {
        document.getElementById("labelImg").innerHTML = '';
        let files = e.target.files;
        let file;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            file = files[i];
            reader.onload = (file) => {
                document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                this.setState({ ChangeImg: "yes", Berkas: reader.result });
            }
            reader.readAsDataURL(file)
        }
    }

    async handleDelete() {
        let sql = await api("delete_data_sales", { ID: this.props.ID, DocNumber: this.state.DocNumber, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            pesan("Berhasil Hapus Data");
            App("Giro");
        } else {
            pesan("Gagal Hapus Data");
        }
    }

    async modalAddGiro(idx = "tidak") {
        if (idx != "tidak") {
            let giro = this.state.tempDataGiro[idx];
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/";
            this.setState({ tempGiroNumber: giro.GiroNumber, tempGiroTermDate: giro.GiroTermDate, tempGiroNominal: giro.GiroNominal, tempCoaCode: giro.CoaCode, tempCoaID: giro.CoaID, tempCoaName: giro.CoaName, tempIndex: idx, tempID: giro.ID, Berkas: this.state.formMode == 'edit' ? `${host}${giro.GiroImg}` : giro.Berkas });
        } else {
            let sql = await api("get_temp_id", { SalesID: this.state.UserID, DocID: this.state.tempDocID, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({ tempGiroNumber: "", tempGiroTermDate: saiki(), tempGiroNominal: 0, tempCoaCode: "", tempCoaID: "", tempCoaName: "", tempIndex: idx, tempID: sql.ID, Berkas: img_upload });
            }
        }
    }

    saveTempGiro(e) {
        e.preventDefault();
        e.stopPropagation();
        let btn = document.getElementById('btnSaveTempGiro');
        btn.disabled = true;
        btn.innerText = "Menyimpan . . .";
        let data = this.state.tempDataGiro;
        let totalGiro = 0;
        let index = this.state.tempIndex;
        this.setState({ tempData: data });
        setTimeout(async () => {
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";
            let DATA = new FormData(e.target);
            fetch(host + 'add_temp_giro', {
                method: 'POST',
                body: DATA,
            }).then(response => response.json()).then(hasil => {
                if (hasil.status === "true") {
                    pesan(hasil.message);
                    if (this.state.tempIndex == "tidak") {
                        data.push({
                            GiroNumber: this.state.tempGiroNumber,
                            GiroTermDate: this.state.tempGiroTermDate,
                            GiroNominal: this.state.tempGiroNominal,
                            CoaCode: this.state.tempCoaCode,
                            CoaName: this.state.tempCoaName,
                            CoaID: this.state.tempCoaID,
                            GiroImg: hasil.Img
                        });
                    } else {
                        data[index] = {
                            GiroNumber: this.state.tempGiroNumber,
                            GiroTermDate: this.state.tempGiroTermDate,
                            GiroNominal: this.state.tempGiroNominal,
                            CoaCode: this.state.tempCoaCode,
                            CoaName: this.state.tempCoaName,
                            CoaID: this.state.tempCoaID,
                            GiroImg: hasil.Img
                        };
                    }
                    for (let giro of data) totalGiro += parseFloat(giro.GiroNominal);
                    this.setState({ tempDataGiro: data, tempGiroNominal: 0, tempGiroTermDate: saiki(), tempGiroNumber: "", tempCoaCode: "", totalGiro: totalGiro, tempData: JSON.stringify([]) });
                    btn.disabled = false;
                    btn.innerText = "Simpan";
                    document.getElementById('btnTutupModalAddGiro').click();
                } else {
                    pesan(hasil.message);
                    btn.disabled = false;
                    btn.innerText = "Simpan";
                }
            }).catch((error) => {
                console.log("Error: " + error);
                pesan("Terjadi kesalahan");
                btn.disabled = false;
                btn.innerText = "Simpan";
            });
        }, 500);
    }

    async deleteTempGiro(idx) {
        let data = this.state.tempDataGiro;
        let sql = await api("delete_temp_giro", { ID: this.state.tempID });
        let totalGiro = 0;
        if (sql.status == 'true') {
            data.splice(idx, 1);
            pesan(sql.message);
            for (let giro of data) totalGiro += parseFloat(giro.GiroNominal);
            this.setState({ tempDataGiro: data, totalGiro: totalGiro });
        } else {
            pesan(sql.message);
        }
    }

    handlePilihBank(Code) {
        let data = this.state.arrBank;
        let CoaName = "", CoaID = "";
        for (let dd of data) {
            if (dd.Code == Code) CoaName = dd.Description;
            if (dd.Code == Code) CoaID = dd.ID;
        }
        this.setState({ tempCoaCode: Code, tempCoaName: CoaName, tempCoaID: CoaID });
    }

    render() {
        let divImg = (<img src={this.state.Berkas} width="256px" height="159px" />);
        return (
            <Fragment>
                <form id="iniForm" onSubmit={(e) => submitForm(e, { form: "#iniForm", reload: true, act: this.props.ID == "" ? "insert_doc" : "edit_doc" })} className="needs-validation" noValidate>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <span className='material-icons' onClick={() => App("Giro")}>chevron_left</span> Input
                            </div>
                            <button className='btn btn-sm' type='button' style={{ color: "red", display: this.state.btnDelete }} onClick={() => this.handleDelete()}>
                                <span className='material-icons'>delete</span> Hapus
                            </button>
                        </div>
                    </div>
                    <div className='container main' id='mainContent'>
                        <input type="hidden" value={this.props.ID} name="ID" />
                        <input type="hidden" value={getCookie("TokenSales")} name="Token" />
                        <input type="hidden" value={this.state.UserID} name="SalesID" />
                        <input type="hidden" value={getCookie("SalesName")} name="SalesName" />
                        <input type="hidden" value="Giro" name="PaymentType" />
                        <input type="hidden" value={this.state.CustomerID} name="CustomerID" />
                        <input type="hidden" value="no" name="ChangeImg" />
                        <input type="hidden" value={this.state.DocDateInvoice} name="DocDateInvoice" />
                        <input type="hidden" value={this.state.TaxType} name="TaxType" />
                        <div className='form-group'>
                            <label>Nama Pelanggan</label>
                            <div className='input-group'>
                                <input type="text" className='form-control' id='edtCustomerName' name="CustomerName" value={this.state.CustomerName} onFocus={() => this.cariPelanggan("")} placeholder='Silahkan Pilih Nama Pelanggan' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalPelanggan" />
                                <button className='btn btn-primary' type="button" onClick={() => this.cariPelanggan("")} data-bs-toggle="modal" data-bs-target="#modalPelanggan"><span className='material-icons'>search</span></button>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Nomor Dokumen</label>
                            <div className='input-group'>
                                <input type="text" className='form-control' name="NoInvoice" value={this.state.NoInvoice} onFocus={() => this.handleInvoiceList("")} placeholder='Silahkan Pilih Nomor Dokumen' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalInvoice" />
                                <button className='btn btn-primary' type="button" onClick={() => this.handleInvoiceList("")} data-bs-toggle="modal" data-bs-target="#modalInvoice"><span className='material-icons'>search</span></button>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Saldo Awal</label>
                            <input type="text" name="AmountOpen" value={this.state.AmountOpen} className="form-control" placeholder='Saldo Awal' readOnly={true} />
                        </div>
                        <div className='form-group'>
                            <label>Pembayaran</label>
                            <input type="number" name="Amount" step="0.01" value={this.state.Amount} onChange={(e) => this.handlePembayaran(e)} max={this.state.AmountOpen} onFocus={(e) => e.target.select()} className="form-control" placeholder='Jumlah Bayar' required />
                            <div className='invalid-feedback'>Silahkan Isi Nilai Pembayaran</div>
                        </div>
                        <input type="hidden" name="Diskon" value={this.state.Diskon} onChange={(e) => this.hanldeDiskon(e)} max={this.state.SaldoAwal} className="form-control" placeholder='Diskon Jika Ada' />
                        <div className='form-group'>
                            <label>Saldo Akhir</label>
                            <input type="text" name="AmountBalance" value={this.state.AmountBalance} className="form-control" placeholder='Saldo Akhir' readOnly={true} />
                        </div>
                        <input type="hidden" value={JSON.stringify(this.state.tempDataGiro)} name="DataGiro" />
                        <div className='form-group'>
                            <label>Tanggal Penagihan</label>
                            <input type="date" name="TanggalPembayaran" min={this.state.DocDate} value={this.state.TanggalPembayaran} onChange={(e) => this.setState({ TanggalPembayaran: e.target.value })} className="form-control" required />
                            <div className="text-danger">Tanggal Tidak boleh dibawah tanggal invoice</div>
                        </div>
                        <button className='btn btn-default w-100' type="button" data-bs-toggle="modal" data-bs-target="#modalAddGiro" onClick={() => this.modalAddGiro("tidak")}>Tambah Giro</button>
                        <div className='table-responsive'>
                            <table className='table table-stripped'>
                                <thead>
                                    <tr>
                                        <th>Nomor Giro</th>
                                        <th>Tanggal</th>
                                        <th>Nominal</th>
                                        <th>Bank</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.tempDataGiro.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.GiroNumber}</td>
                                                    <td>{tanggalIndo(item.GiroTermDate)}</td>
                                                    <td>{numberFormat(item.GiroNominal)}</td>
                                                    <td>{item.CoaName}</td>
                                                    <td>
                                                        <span className='material-icons' data-bs-toggle="modal" data-bs-target="#modalAddGiro" onClick={() => this.modalAddGiro(String(index, item.ID))}>edit</span>
                                                        <span className='material-icons' onClick={() => this.deleteTempGiro(String(index))}>delete</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="2">Total</td>
                                        <td style={{ color: this.state.totalGiro != this.state.Amount ? "red" : "black" }}>{numberFormat(this.state.totalGiro)}</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <button className='btn btn-default w-100' type='submit' disabled={this.state.totalGiro == this.state.Amount ? false : true}>Simpan</button>
                    </div>
                </form>

                <div className="modal fade" id="modalAddGiro" tabIndex="-1" aria-labelledby="modalAddGiro" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <form onSubmit={(e) => this.saveTempGiro(e)} id="formAddGiro">
                            <input type="hidden" name="ID" value={this.state.tempID} />
                            <input type="hidden" value={getCookie("TokenSales")} name="Token" />
                            <input type="hidden" name="ChangeImg" value={this.state.ChangeImg} />
                            <input type="hidden" name="formMode" value={this.state.formMode} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel" onClick={(e) => this.deleteTempGiro()}>Tambah Giro</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <div className='form-group'>
                                        <label>Nomor Giro</label>
                                        <input type="text" className='form-control' placeholder='Nomor Giro' value={this.state.tempGiroNumber} onChange={(e) => this.setState({ tempGiroNumber: e.target.value })} required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Tanggal Jatuh Tempo Giro</label>
                                        <input type="date" className='form-control' value={this.state.tempGiroTermDate} onChange={(e) => this.setState({ tempGiroTermDate: e.target.value })} required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Nominal</label>
                                        <input type="number" className='form-control' step="0.01" placeholder='Nomor Giro' value={this.state.tempGiroNominal} onChange={(e) => this.setState({ tempGiroNominal: e.target.value })} onFocus={(e) => e.target.select()} min="1" required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Akun Bank</label>
                                        <select className='form-select' value={this.state.tempCoaCode} onChange={(e) => this.handlePilihBank(e.target.value)} required>
                                            <option value="">Silahkan pilih Bank</option>
                                            {
                                                this.state.arrBank.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.Code}>{item.Code} - {item.Description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Bukti</label>
                                        <div className='image-upload'>
                                            <label id="labelImg" htmlFor="image-upload">
                                                {divImg}
                                            </label>
                                            <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} />
                                            <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                        </div>
                                    </div>
                                    <input type="hidden" name="Data" value={JSON.stringify(this.state.tempData)} />
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary" id='btnSaveTempGiro'>Simpan</button>
                                    <button type="button" className="btn btn-secondary" id='btnTutupModalAddGiro' data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal fade" id="modalPelanggan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Data Pelanggan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.cariPelanggan(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Telp</th>
                                                <th>Alamat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listPelanggan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihPelanggan(index)} key={index}>
                                                            <td>{item.Name}</td>
                                                            <td>{item.Telp}</td>
                                                            <td>{item.Address}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalPelanggan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalInvoice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Data Tagihan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Data Tagihan' onChange={(e) => this.handleInvoiceList(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>No Invoice</th>
                                                <th>Tanggal</th>
                                                <th>Usia</th>
                                                <th>PPN</th>
                                                <th>Saldo Awal</th>
                                                <th>Pending</th>
                                                <th>Sisa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listTagihan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihTagihan(index)} key={index}>
                                                            <td>{item.DocNumber}</td>
                                                            <td>{tanggalIndo(item.DocDate)}</td>
                                                            <td>{item.UmurNota}</td>
                                                            <td>{item.TaxType}</td>
                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                            <td>{numberFormat(item.Pending)}</td>
                                                            <td>{numberFormat(item.AmountBalance - item.Pending)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={4}>Total {this.state.listTagihan.length}</td>
                                                <td>{numberFormat(this.state.totalSaldoAwal)}</td>
                                                <td>{numberFormat(this.state.totalTagihanPending)}</td>
                                                <td>{numberFormat(this.state.totalTagihan)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalTagihan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetailTagihan" tabIndex="-1" aria-labelledby="modalDetailTagihan" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Detail Setoran</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnTutupModalDetailPembayaran'></button>
                            </div>
                            <div className="modal-body" id='modalBodyInvoice'>
                                <div className='table-responsive'>
                                    <h6>Detail Penagihan Tertunda</h6>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Tanggal</th>
                                                <th>Pembayaran</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.detailTagihan.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                            <td>{numberFormat(item.Amount)}</td>
                                                            <td>{item.State}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={2}><b>Total</b></td>
                                                <td><b>{numberFormat(this.state.SaldoTertagih)}</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <h6>Rincian</h6>
                                    <table className='table table-striped'>
                                        <tbody>
                                            <tr>
                                                <td>Saldo Awal</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoOpen)}</td>
                                            </tr>
                                            <tr>
                                                <td>Penagihan yang Tertunda</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoTertagih)}</td>
                                            </tr>
                                            <tr>
                                                <td>Sisa Tagihan</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.SaldoSisa)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => this.handleCancelPilihTrans()}>Batal</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.handlePilihTrans2()} disabled={this.state.AmountOpen > 0 ? false : true}>Pilih</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
// END GIRO

export default App;