import './App.css';
import React, { Fragment } from 'react';
import { api, getCookie, setCookie, pesan, router } from './modul'

function App() {
  class Main extends React.Component {
    constructor (props) {
      super(props);
      this.state = {
        dom: []
      };
    }

    async componentDidMount() {
      if (getCookie("TokenSales") != "") {
        router();
      } else {
        this.rendLogin();
      }
    }

    rendLogin() {
      let dom = <Fragment>
        <div className='header-login'></div>
        <div className='card-form-login'>
          <form onSubmit={(e) => this.handleLogin(e)} id="formLogin" className='needs-validation'>
            <h5 style={{ paddingTop: "32px", paddingBottom: "24px", fontSize: "16px" }}>Masuk</h5>
            <div className='form-login'>
              <span className='material-icons'>vpn_key_off</span>
              <input type="text" className='form-control form-control-login form-search' id="edtCode" placeholder='Masukan Kode Perusahaan' required />
            </div>
            <div className='form-login'>
              <span className='material-icons'>email</span>
              <input type="text" className='form-control form-control-login form-search' id="edtEmail" placeholder='Masukan Username' required />
            </div>
            <div className='form-login'>
              <span className='material-icons'>vpn_key</span>
              <input type="password" className='form-control form-control-login form-search' id="edtPwd" placeholder='Katasandi' required />
            </div>
            <button type="submit" id='btnLogin' className='btn btn-login'><span id='lblLogin'></span> Login</button>
          </form>
        </div>
      </Fragment>
      this.setState({ dom: dom });
    }

    async handleLogin(e) {
      e.preventDefault();
      e.stopPropagation();
      let btn = document.getElementById('btnLogin');
      let lbl = document.getElementById('lblLogin');
      btn.disabled = true;
      lbl.className = "spinner-border spinner-border-sm text-danger";
      let sql = await api("login_sales", {
        Username: document.getElementById('edtEmail').value,
        Pwd: document.getElementById('edtPwd').value,
        Code: document.getElementById('edtCode').value
      });
      if (sql.status == "true") {
        setCookie("SalesID", sql.data.AgentID);
        setCookie("SalesName", sql.data.Username);
        setCookie("CompanyProfile", sql.profile);
        setCookie("TokenSales", sql.Token);
        window.location.reload();
      } else {
        btn.disabled = false;
        lbl.className = "";
        pesan(sql.message);
      }
    }

    render() {
      return (
        this.state.dom
      )
    }
  }

  return <Main />
}

window.addEventListener('popstate', (event) => {
  router();
});

export default App;
