import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { api, getCookie, logout, pesan } from '../modul';
import Footer from '../component/Footer';
import ImgProfile from '../newprofile.png';

function App() {
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                data: [],
                oldPwd: "",
                newPwd: "",
                reNewPwd: ""
            }
        }

        async handleChangePassword(e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.state.newPwd == this.state.reNewPwd) {
                let sql = await api("ganti_password", { SalesID: getCookie("SalesID"), OldPwd: this.state.oldPwd, NewPwd: this.state.newPwd, Token: getCookie("Token") });
                if (sql.status == "true") {
                    pesan(sql.message);
                    document.getElementById('btnTutupModal').click();
                } else {
                    pesan(sql.message);
                }
            } else {
                pesan("Password baru tidak sama dengan ulangi password baru");
            }
        }

        render() {
            return (
                <Fragment>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <h5 style={{ textAlign: "center", fontSize: "14px" }}>Profile : <b>{getCookie("CompanyProfile")}</b></h5>
                    </div>
                    <div className='container main' id='mainContent'>
                        <center>
                            <img src={ImgProfile} style={{ width: "150px", height: "150px", borderRadius: "50%" }} />
                            <h5><b>{getCookie("SalesName")}</b></h5>
                        </center>
                        <div className='table-responsive'>
                            <ul class="list-group">
                                <li class="list-group-item" data-bs-toggle="modal" data-bs-target="#modal">Ganti Password</li>
                                <li class="list-group-item" onClick={() => logout()}>Logout</li>
                            </ul>
                        </div>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <Footer active="Profile" />
                    </div>

                    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-fullscreen">
                            <div class="modal-content">
                                <form onSubmit={(e) => this.handleChangePassword(e)}>
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Ganti Password</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div className='form-group'>
                                            <label>Password Lama</label>
                                            <input type="password" className='form-control' id='oldPassword' value={this.state.oldPwd} onChange={(e) => this.setState({ oldPwd: e.target.value })} placeholder='Password Lama' required />
                                        </div>
                                        <div className='form-group'>
                                            <label>Password Baru</label>
                                            <input type="password" className='form-control' id='newPassword' value={this.state.newPwd} onChange={(e) => this.setState({ newPwd: e.target.value })} placeholder='Password Baru' required />
                                        </div>
                                        <div className='form-group'>
                                            <label>Ulangi Password Baru</label>
                                            <input type="password" className='form-control' id='reNewPassword' value={this.state.reNewPwd} onChange={(e) => this.setState({ reNewPwd: e.target.value })} placeholder='Ulangi Password Baru' required />
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" className="btn btn-secondary" id='btnTutupModal' data-bs-dismiss="modal">Batal</button>
                                        <button type="submit" className="btn btn-primary">Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    let dom = ReactDOM.createRoot(document.getElementById('root'));
    dom.render(<Main />);
}

export default App;