import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { api, getCookie, submitForm, saiki, tanggalIndo, numberFormat, pesan } from '../modul';
import Footer from '../component/Footer';
import img_upload from '../bg_uppload-image.png';

function App() {
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                data: []
            }
        }

        async componentDidMount() {
            let sql = await api("list_tagihan", { SalesID: getCookie("SalesID"), PaymentType: "Tunai", Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({ data: sql.data });
            }
        }

        handleAdd(ID = "") {
            let Header = localStorage.getItem("Header");
            if (Header == 0) {
                dom.render(<Form ID={ID} />);
            } else {
                pesan("Dokumen dalam proses verifikasi");
            }
        }

        handleEdit(ID) {
            dom.render(<Form ID={ID} />)
        }

        render() {
            return (
                <Fragment>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <h5 style={{ textAlign: "center", fontSize: "14px" }}>Penagihan Tunai</h5>
                    </div>
                    <div className='container main' id='mainContent'>
                        <div className='table-responsive'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>Pelanggan</th>
                                        <th>Invoice</th>
                                        <th>Umur</th>
                                        <th>Pembayaran</th>
                                        <th>Sisa</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            let Status = <span className='badge text-bg-primary'>Lunas</span>;
                                            if (item.SaldoAkhir > 0) Status = <span className='badge text-bg-danger'>Kredit</span>;
                                            return (
                                                <tr onClick={() => this.handleEdit(item.ID)}>
                                                    <td>{item.CustomerName}</td>
                                                    <td>{item.NoInvoice}</td>
                                                    <td>{item.UmurNota}</td>
                                                    <td>{numberFormat(item.Pembayaran)}</td>
                                                    <td>{numberFormat(item.SaldoAkhir)}</td>
                                                    <td>{Status}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <button className='btn btn-primary' style={{ position: "absolute", right: "20px", bottom: "80px", borderRadius: "50%" }} onClick={() => this.handleAdd()}>+</button>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <Footer active="Tunai" />
                    </div>
                </Fragment>
            )
        }
    }

    let dom = ReactDOM.createRoot(document.getElementById('root'));
    dom.render(<Main />);
}

class Form extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            UserID: getCookie("SalesID"),
            NoInvoice: "",
            SaldoAwal: "",
            Pembayaran: "",
            Diskon: "",
            SaldoAkhir: "",
            CustomerID: "",
            CustomerName: "",
            PaymentType: "Tunai",
            GiroNumber: "",
            TanggalJatuhTempoGiro: "",
            Berkas: "",
            ChangeImg: "no",
            DocDateInvoice: saiki(),
            listTagihan: [],
            listPelanggan: [],
            btnDelete: "none",
            TanggalPenagihan: saiki()
        }
    }

    async componentDidMount() {
        let ID = this.props.ID;
        if (ID != "") {
            let sql = await api("detail_penagihan", { ID: ID, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({
                    NoInvoice: sql.data.NoInvoice,
                    SaldoAwal: sql.data.SaldoAwal,
                    Pembayaran: sql.data.Pembayaran,
                    Diskon: sql.data.Diskon,
                    SaldoAkhir: sql.data.SaldoAkhir,
                    CustomerID: sql.data.CustomerID,
                    CustomerName: sql.data.CustomerName,
                    GiroNumber: sql.data.GiroNumber,
                    TanggalJatuhTempoGiro: sql.data.TanggalJatuhTempoGiro,
                    Berkas: sql.data.Berkas,
                    DocDateInvoice: sql.data.DocDateInvoice2,
                    btnDelete: "block",
                    TanggalPenagihan: sql.data.TanggalPenagihan
                });
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        console.log(e);
    }

    async handleInvoiceList(q = "") {
        if (this.state.CustomerID != "") {
            let sql = await api("arap_list", { CardID: this.state.CustomerID, Token: getCookie("TokenSales")});
            if (sql.status == "true") {
                this.setState({ listTagihan: sql.data });
            }
        } else {
            alert("Silahkan pilih pelanggan terlebih dahulu");
            document.getElementById('edtCustomerName').focus();
            setTimeout(() => {
                document.getElementById('btnTutupModalTagihan').click();
            }, 500);
        }
    }

    async cariPelanggan(q) {
        let sql = await api("list_pelanggan", { q: q, SalesID: this.state.UserID, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            this.setState({ listPelanggan: sql.data });
        }
    }

    handlePilihPelanggan(i) {
        let pelanggan = this.state.listPelanggan[i];
        this.setState({
            CustomerID: pelanggan.ID,
            CustomerName: pelanggan.Name,
            NoInvoice: "",
            SaldoAwal: 0,
            Pembayaran: 0,
            Diskon: 0,
            SaldoAkhir: 0,
            GiroNumber: "",
            TanggalJatuhTempoGiro: saiki(),
            Berkas: "",
            DocDateInvoice: saiki(),
            TanggalPenagihan: saiki()
        });
        document.getElementById('btnTutupModalPelanggan').click();
    }

    handlePilihTagihan(i) {
        let tagihan = this.state.listTagihan[i];
        this.setState({
            NoInvoice: tagihan.DocNumber,
            SaldoAwal: tagihan.AmountBalance,
            DocDateInvoice: tagihan.DocDateInvoice,
            SaldoAkhir: 0,
            Pembayaran: 0,
            Diskon: 0,
            GiroNumber: "",
            TanggalJatuhTempoGiro: saiki(),
            Berkas: "",
            TanggalPenagihan: saiki()
        });
        document.getElementById('btnTutupModalTagihan').click();
    }

    handlePembayaran(e) {
        let val = e.target.value;
        let total = this.state.SaldoAwal - val - this.state.Diskon;
        this.setState({ Pembayaran: val, SaldoAkhir: total });
    }

    hanldeDiskon(e) {
        let val = e.target.value;
        let total = this.state.SaldoAwal - this.state.Pembayaran - val;
        this.setState({ Diskon: val, SaldoAkhir: total });
    }

    openFile(e) {
        document.getElementById("labelImg").innerHTML = '';
        let files = e.target.files;
        let file;
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            file = files[i];
            reader.onload = (file) => {
                document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                this.setState({ ChangeImg: "yes" });
            }
            reader.readAsDataURL(file)
        }
    }

    async handleDelete() {
        let sql = await api("delete_data_sales", { ID: this.props.ID, Token: getCookie("TokenSales") });
        if (sql.status == "true") {
            pesan("Berhasil Hapus Data");
            App();
        } else {
            pesan("Gagal Hapus Data");
        }
    }

    render() {
        let divImg = (
            <img src={img_upload} width="256px" height="159px" />
        );

        if (this.state.Berkas != "") {
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://penagihanapi.pxa.co.id/";
            divImg = <img src={host + "file/" + this.state.Berkas} width="256px" height="159px" />
        }

        return (
            <Fragment>
                <form id="iniForm" onSubmit={(e) => submitForm(e, { form: "#iniForm", reload: true, act: this.props.ID == "" ? "insert_doc" : "edit_doc" })} className="needs-validation" novalidate>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <span className='material-icons' onClick={() => App()}>chevron_left</span> Tunai
                            </div>
                            <button className='btn btn-sm' type='button' style={{ color: "red", display: this.state.btnDelete }} onClick={() => this.handleDelete()}>
                                <span className='material-icons'>delete</span> Hapus
                            </button>
                        </div>
                    </div>
                    <div className='container main' id='mainContent'>
                        <input type="hidden" value={this.props.ID} name="ID" />
                        <input type="hidden" value={getCookie("SalesID")} name="UserID" />
                        <input type="hidden" value={getCookie("SalesName")} name="SalesName" />
                        <input type="hidden" value={getCookie("TokenSales")} name="Token" />
                        <input type="hidden" value="Tunai" name="PaymentType" />
                        <input type="hidden" value={this.state.CustomerID} name="CustomerID" />
                        <input type="hidden" value={this.state.ChangeImg} name="ChangeImg" />
                        <input type="hidden" value={this.state.DocDateInvoice} name="DocDateInvoice" />
                        <div className='form-group'>
                            <label>Nama Pelanggan</label>
                            <div className='input-group'>
                                <input type="text" className='form-control' id='edtCustomerName' name="CustomerName" value={this.state.CustomerName} onFocus={() => this.cariPelanggan("")} placeholder='Silahkan Pilih Nama Pelanggan' readOnly="true" data-bs-toggle="modal" data-bs-target="#modalPelanggan" />
                                <button className='btn btn-primary' type="button" onClick={() => this.cariPelanggan("")} data-bs-toggle="modal" data-bs-target="#modalPelanggan"><span className='material-icons'>search</span></button>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Nomor Dokumen</label>
                            <div className='input-group'>
                                <input type="text" className='form-control' name="NoInvoice" value={this.state.NoInvoice} onFocus={() => this.handleInvoiceList("")} placeholder='Silahkan Pilih Nomor Dokumen' readOnly="true" data-bs-toggle="modal" data-bs-target="#modalInvoice" />
                                <button className='btn btn-primary' type="button" onClick={() => this.handleInvoiceList("")} data-bs-toggle="modal" data-bs-target="#modalInvoice"><span className='material-icons'>search</span></button>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Saldo Awal</label>
                            <input type="text" name="SaldoAwal" value={this.state.SaldoAwal} className="form-control" placeholder='Saldo Awal' readOnly="true" />
                        </div>
                        <div className='form-group'>
                            <label>Pembayaran</label>
                            <input type="number" name="Pembayaran" step="0.01" value={this.state.Pembayaran} onChange={(e) => this.handlePembayaran(e)} max={this.state.SaldoAwal} className="form-control" placeholder='Jumlah Bayar' required />
                            <div className='invalid-feedback'>Silahkan Isi Nilai Pembayaran</div>
                        </div>
                        <input type="hidden" name="Diskon" value={this.state.Diskon} onChange={(e) => this.hanldeDiskon(e)} max={this.state.SaldoAwal} className="form-control" placeholder='Diskon Jika Ada' />
                        {/* <div className='form-group'>
                            <label>Diskon</label>
                        </div> */}
                        <div className='form-group'>
                            <label>Saldo Akhir</label>
                            <input type="text" name="SaldoAkhir" value={this.state.SaldoAkhir} className="form-control" placeholder='Saldo Akhir' readOnly="true" />
                        </div>
                        <div className='form-group'>
                            <label>Tanggal Penagihan</label>
                            <input type="date" name="TanggalPelunasan" value={this.state.TanggalPenagihan} className="form-control" placeholder='Tanggal Penagihan' required />
                        </div>
                        <div className="form-group">
                            <label>Bukti</label>
                            <div className='image-upload'>
                                <label id="labelImg" for="image-upload">
                                    {divImg}
                                </label>
                                <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} />
                                <div className="invalid-feedback">Silahkan pilih Gambar</div>
                            </div>
                        </div>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <button className='btn btn-default w-100' type='submit' disabled={this.state.SaldoAkhir >= 0 ? false : true}>Simpan</button>
                    </div>
                </form>

                <div class="modal fade" id="modalPelanggan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Data Pelanggan</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.cariPelanggan(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Telp</th>
                                                <th>Alamat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listPelanggan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihPelanggan(index)}>
                                                            <td>{item.Name}</td>
                                                            <td>{item.Telp}</td>
                                                            <td>{item.Address}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalPelanggan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="modalInvoice" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Data Tagihan</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body" id='modalBodyInvoice'>
                                <input className='form-control' placeholder='Cari Data Tagihan' onChange={(e) => this.handleInvoiceList(e.target.value)} />
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-sm table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>No Invoice</th>
                                                <th>Tanggal</th>
                                                <th>Usia</th>
                                                <th>Nilai</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.listTagihan.map((item, index) => {
                                                    return (
                                                        <tr onClick={() => this.handlePilihTagihan(index)}>
                                                            <td>{item.DocNumber}</td>
                                                            <td>{tanggalIndo(item.DocDate)}</td>
                                                            <td>{item.UmurNota}</td>
                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-secondary" id='btnTutupModalTagihan' data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default App;