import Tunai from './page/Tunai';
import Transfer from './page/Transfer';
import Giro from './page/Giro';

import Salesorder from './page/Riwayat';

// import Salesorder from './page/Salesorder';
import Stok from './page/Stok';
import Penagihan from './page/Penagihan';
import Setoranbank from './page/Setoranbank';
import Profile from './page/Profile';

let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";
// let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "http://192.168.9.149:2005/api_mobile/";
export const TampilBulan = function (date) {
    let bulan = date.substring(5);
    let tahun = date.substring(0, 4);
    let BulanIndo = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agust", "Sept", "Okt", "Nov", "Des"];
    let hasil = `${BulanIndo[bulan - 1]} ${tahun}`;
    return hasil;
}

export const saiki = function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
}

export const pesan = function (pesan, waktu = 5000) {
    var x = document.getElementById("snackbar");
    x.innerText = pesan;
    x.className = "show";
    setTimeout(function () {
        x.className = x.className.replace("show", "");
        x.innerText = "";
    }, 3000);
}

export const isJson = function (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = function (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const checkCookie = function () {
    var user = getCookie("username");
    if (user != "") {
        alert("Welcome again " + user);
    } else {
        user = prompt("Please enter your name:", "");
        if (user != "" && user != null) {
            setCookie("username", user, 365);
        }
    }
}

export const logout = function logout() {
    sessionStorage.clear();
    setCookie("SalesID", "", -30);
    setCookie("SalesName", "", -30);
    setCookie("CompanyProfile", "", -30);
    setCookie("TokenSales", "", -30);
    window.location.href = './';
}

export const YYMMDD = function (date) {
    var d = new Date(date),
        month = '' + (d.getMonth()),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const tanggalIndo = function (data) {
    var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let hasil = [year, month, day].join('-');

    if (hasil == "0000-00-00" || hasil == null) {
        return (hasil);
    } else {
        let BulanIndo = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agust", "Sept", "Okt", "Nov", "Des"];

        let tahun = hasil.substring(2, 4);
        let bulan = hasil.substring(5, 7);
        let tgl = hasil.substring(8, 10);

        let result = `${tgl} ${BulanIndo[bulan - 1]} ${tahun}`;
        return result;
    }
}

export const randomRgb = () => {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
}

export const getDayOfMonth = function (month, year) {
    var date = new Date(Date.UTC(year, month, 1));
    var days = [];
    // let bln = month < 10 ? month.substring(1) : month;
    while (date.getUTCMonth() === month) {
        days.push(YYMMDD(new Date(date)));
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
}

export const getLastDay = (y, m) => {
    var d = new Date(y, m, 0);
    return `${y}-${m}-${d.getDate()}`;
}

export const getAllDay = function (date) {
    var month = date.substring(5, 7);
    var year = date.substring(0, 4);
    var d = new Date(year, month, 0).getDate();
    let day = [];
    for (let i = 1; i <= d; i++) {
        day.push(`${year}-${month}-${i}`);
    }
    return day;
}

export const numberFormat = function (ini) {
    var formatter = new Intl.NumberFormat("en-GB", { style: "decimal" });
    var nmr = 0;
    if (isNaN(ini)) {
        nmr = 0;
    } else {
        nmr = ini;
    }
    return formatter.format(nmr.toString().replace(/,/g, ""));
}

export const submitForm = function (e, opt) {
    e.preventDefault();
    e.stopPropagation();
    let f = opt.form ? opt.form : "#iniForm";
    let form = document.querySelector(f);
    let btn = form.querySelectorAll("button[type='submit']")[0];
    let oldBtn = btn.innerHTML.trim();
    let clsModal = opt.modal ? opt.modal : "no";
    if (form.checkValidity()) {
        btn.innerHTML = `<span class="spinner-border spinner-border-sm"></span> ${oldBtn}`;
        btn.disabled = true;
        let data = new FormData(form);
        fetch(host + opt.act, {
            method: 'POST',
            body: data,
        }).then(response => response.json()).then(hasil => {
            if (hasil.status === "true") {
                pesan("Proses Success", hasil.message, "primary");
                if (opt.reload === true) router();
                if (opt.click) document.getElementById(opt.click).click();
                if (clsModal !== "no") document.getElementById(clsModal).click();
            } else {
                pesan(hasil.message);
                btn.innerHTML = oldBtn;
                btn.disabled = false;
            }
        }).catch((error) => {
            console.log("Error: " + error);
            btn.innerHTML = oldBtn;
            btn.disabled = false;
        });
    } else {
        let forms = document.getElementsByClassName('needs-validation');
        let ululu = Array.prototype.filter.call(forms, function (form) {
            form.classList.add('was-validated');
        });
    }
}

export const api = function (url, dd, cors = true) {
    let Url = cors == true ? host + url : url;
    try {
        return new Promise((resolve, reject) => {
            fetch(encodeURI(Url), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dd),
            }).then(response => response.json()).then(hasil => {
                resolve(hasil);
            }).catch((error) => {
                reject(error)
            });
        });
    } catch (e) {
        pesan("Terjadi Kesalahan", "Mohon maaf terjadi kesalahan saat load data" + e, "danger");
        console.log(e);
    }
}

export const pilihMenu = function (path, title, ch = false) {
    if (ch == true) window.history.pushState(path, title, path);
    router();
}

export const router = function (path, title, ch = false) {
    let pathname = window.location.pathname;
    let Path = pathname.split("/");
    switch (Path[1]) {
        case "/":
            Salesorder();
            break;

        case 'Stok':
            Stok();
            break;

        case 'Penagihan':
            Penagihan();
            break;

        case 'Setoranbank':
            Setoranbank();
            break;

        case 'Profile':
            Profile();
            break;

        default:
            Salesorder();
            break;
    }
}