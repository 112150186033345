import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { api, getCookie, submitForm, pesan, tanggalIndo, numberFormat, saiki, router } from '../modul';
import Footer from '../component/Footer';

function App() {
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                DocNumber: ""
            }
        }

        async componentDidMount() {
            let sql = await api("list_tagihan", { SalesID: getCookie("SalesID"), PaymentType: "", Token: getCookie("TokenSales"), Token: getCookie("TokenSales") });
            if (sql.status == "true") this.setState({ data: sql.data, DocNumber: sql.DocNumber });
        }

        async handleProses() {
            let btn = document.getElementById('btnHandleSetor');
            btn.disabled = true;
            btn.innerText = 'Memproses . . .';
            let sql = await api("approve_sales_doc", { DocNumber: this.state.DocNumber, SalesID: getCookie("SalesID"), Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                pesan("Proses Success", sql.message, "primary");
                btn.disabled = false;
                btn.innerText = 'Setor Semua';
                router();
            } else {
                pesan("Proses Error", sql.message, "danger");
                btn.disabled = false;
                btn.innerText = 'Setor Semua';
            }
        }

        render() {
            return (
                <Fragment>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <h5 style={{ textAlign: "center", fontSize: "14px" }}>Penagihan Setoran</h5>
                    </div>
                    <div className='container main' id='mainContent'>
                        <div className='table-responsive'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>Pelanggan</th>
                                        <th>Invoice</th>
                                        <th>Pembayaran</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            let Status = <span className='badge text-bg-primary'>Lunas</span>;
                                            if (item.AmountBalance > 0) Status = <span className='badge text-bg-danger'>Kredit</span>;
                                            return (
                                                <tr onClick={() => this.handleEdit(item.ID)} key={index}>
                                                    <td>{item.CustomerName}</td>
                                                    <td>{item.NoInvoice}</td>
                                                    <td>{numberFormat(item.Amount)}</td>
                                                    <td>{item.PaymentType}</td>
                                                    <td>{Status}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <button className='btn btn-primary w-100' onClick={() => this.handleProses()} disabled={this.state.data.length > 0 ? false : true} id="btnHandleSetor">Setor Semua</button>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <Footer active="Setoranbank" />
                    </div>
                </Fragment>
            )
        }
    }

    let dom = ReactDOM.createRoot(document.getElementById('root'));
    dom.render(<Main />);
}

export default App;