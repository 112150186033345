import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { api, getCookie, submitForm, pesan, tanggalIndo, numberFormat, saiki, router } from '../modul';
import Footer from '../component/Footer';

function App() {
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                data: [],
                ItemName: ""
            }
        }

        async componentDidMount() {
            let sql = await api("cek_stok", { SalesID: getCookie("SalesID"), ItemName: this.state.ItemName, Token: getCookie("TokenSales") });
            if (sql.status == "true") this.setState({ data: sql.data });
        }

        async handleCari() {
            let sql = await api("cek_stok", { SalesID: getCookie("SalesID"), ItemName: this.state.ItemName, Token: getCookie("TokenSales") });
            if (sql.status == "true") this.setState({ data: sql.data });
        }

        render() {
            return (
                <Fragment>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <h5 style={{ textAlign: "center", fontSize: "14px" }}>Stok Barang</h5>
                    </div>
                    <div className='container main' id='mainContent'>
                        <div className='input-group'>
                            <input className='form-control' placeholder='Cari Nama Barang' value={this.state.ItemName} onChange={(e) => this.setState({ ItemName: e.target.value })} />
                            <button className='btn btn-primary' onClick={() => this.handleCari()}>
                                <span className='material-icons'>search</span>
                            </button>
                        </div>
                        <div className='table-responsive'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>Kode</th>
                                        <th>Nama</th>
                                        <th>Stok</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            let Status = <span className='badge text-bg-primary'>Lunas</span>;
                                            if (item.SaldoAkhir > 0) Status = <span className='badge text-bg-danger'>Kredit</span>;
                                            return (
                                                <tr>
                                                    <td>{item.Code}</td>
                                                    <td>{item.Name}</td>
                                                    <td>{numberFormat(item.QtyUnit)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <Footer active="Stok" />
                    </div>
                </Fragment>
            )
        }
    }

    let dom = ReactDOM.createRoot(document.getElementById('root'));
    dom.render(<Main />);
}

export default App;