import React, { Fragment } from 'react';
import { pilihMenu, api, getCookie } from '../modul';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CTTunai: 0,
            CTTransfer: 0,
            CTGiro: 0,
        }
    }

    hanldeMenu(menu) {
        let cls = document.getElementsByClassName('btn-menu');
        for (let i = 0; i < cls.length; i++) {
            cls[i].classList.remove('active');
            let icon = cls[i].getElementsByClassName('icon-menu')[0];
            if (icon) icon.classList.remove('active');
        }

        switch (menu) {
            case 'menuRiwayat':
                pilihMenu("/", "Riwayat", true);
                break;

            case 'menuStok':
                pilihMenu("/Stok", "Stok Barang", true);
                break;

            case 'menuPenagihan':
                pilihMenu("/Penagihan", "Penagihan", true);
                break;

            case 'menuSetoranbank':
                pilihMenu("/Setoranbank", "Setoran Bank", true);
                break;

            case 'menuProfile':
                pilihMenu("/Profile", "Profile", true);
                break;
        }
    }

    render() {
        let Active = this.props.active;
        let Riwayat = Active == 'Riwayat' ? ' active' : '';
        let Stok = Active == 'Stok' ? ' active' : '';
        let Penagihan = Active == 'Penagihan' ? ' active' : '';
        let Setoranbank = Active == 'Setoranbank' ? ' active' : '';
        let Profile = Active == 'Profile' ? ' active' : '';

        return (
            <Fragment>
                <div className='d-flex justify-content-center align-items-center'>
                    <button type='button' className={"btn btn-menu" + Riwayat} onClick={() => this.hanldeMenu("menuRiwayat")} id="menuRiwayat">
                        <span className={"material-icons icon-menu" + Riwayat}>update</span>
                        <br />
                        <div className='d-flex justify-content-center align-items-center gap-1'>
                            <span style={{ fontSize: "12px" }}>Riwayat</span>
                        </div>
                    </button>
                    <button type='button' className={"btn btn-menu" + Stok} onClick={() => this.hanldeMenu("menuStok")} id="menuStok">
                        <span className={"material-icons icon-menu" + Stok}>library_books</span>
                        <br />
                        <div className='d-flex justify-content-center align-items-center gap-1'>
                            <span style={{ fontSize: "12px" }}>Stok</span>
                        </div>
                    </button>
                    <button type='button' className={"btn btn-menu" + Penagihan} onClick={() => this.hanldeMenu("menuPenagihan")} id="menuPenagihan">
                        <span className={"material-icons icon-menu" + Penagihan}>handshake</span>
                        <br />
                        <div className='d-flex justify-content-center align-items-center gap-1'>
                            <span style={{ fontSize: "12px" }}>Tagihan</span>
                        </div>
                    </button>
                    <button type='button' className={"btn btn-menu" + Setoranbank} onClick={() => this.hanldeMenu("menuSetoranbank")} id="menuSetoranbank">
                        <span className={"material-icons icon-menu" + Setoranbank}>backup_table</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>Setoran</span>
                    </button>
                    <button type='button' className={"btn btn-menu" + Profile} onClick={() => this.hanldeMenu("menuProfile")} id="menuProfile">
                        <span className={"material-icons icon-menu" + Profile}>person</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>Profile</span>
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default Main;