import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import { api, getCookie, saiki, tanggalIndo, numberFormat, pesan } from '../modul';
import Footer from '../component/Footer';
const Root = createRoot(document.getElementById('root'));

function App() {
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                d1: saiki("01"),
                d2: saiki(),
                Filter: "DocDate"
            }
        }

        async componentDidMount() {
            let sql = await api("list_tagihan_sales", { Filter: this.state.Filter, SalesID: getCookie("SalesID"), d1: this.state.d1, d2: this.state.d2, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({ data: sql.data });
            }
        }

        async handleCari() {
            let sql = await api("list_tagihan_sales", { Filter: this.state.Filter, SalesID: getCookie("SalesID"), d1: this.state.d1, d2: this.state.d2, Token: getCookie("TokenSales") });
            if (sql.status == "true") {
                this.setState({ data: sql.data });
            }
        }

        render() {
            return (
                <Fragment>
                    <div className='container header' id='mainHeader' style={{ padding: "15px" }}>
                        <h5 style={{ textAlign: "center", fontSize: "14px" }}>Riwayat Penagihan</h5>
                    </div>
                    <div className='container-fluit main' id='mainContent'>
                        <div className='input-group gap-2'>
                            <select className="form-select form-select-sm" value={this.state.Filter} onChange={(e) => this.setState({ Filter: e.target.value })} >
                                <option value="DocDate">Tanggal Buat</option>
                                <option value="TanggalPembayaran">Tanggal Pembayaran</option>
                            </select>
                            <input type="date" className="form-control form-control-sm" value={this.state.d1} onChange={(e) => this.setState({ d1: e.target.value })} />
                            <input type="date" className="form-control form-control-sm" value={this.state.d2} onChange={(e) => this.setState({ d2: e.target.value })} />
                            <button className='btn btn-sm btn-primary' onClick={() => this.handleCari()}><span className='material-icons'>search</span></button>
                        </div>
                        <p></p>
                        <div className='table-responsive'>
                            <table className='table table-bordered table-sm' style={{ minWidth: "800px" }}>
                                <thead className='bg-dark text-light'>
                                    <tr>
                                        <th>No</th>
                                        <th>Tanggal Pembayaran</th>
                                        <th>Tanggal Buat</th>
                                        <th>Pelanggan</th>
                                        <th>Total</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.NoInvoice}</td>
                                                    <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                    <td>{tanggalIndo(item.DocDate)}</td>
                                                    <td>{item.CustomerName}</td>
                                                    <td>{numberFormat(item.Amount)}</td>
                                                    <td>{item.State}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <p style={{ marginTop: "100px" }}></p>
                    </div>
                    <div className='container footer' id='divFooter'>
                        <Footer active="Riwayat" />
                    </div>
                </Fragment>
            )
        }
    }
    Root.render(<Main />);
}

export default App;